.wrapper {
  position: relative;
}
.disaster {
  position: relative;
  padding-bottom: 114px;
  @include mq {
    padding-bottom: 100px;
  }
  .main-ttl {
    &::after {
      content: "";
      background: $color07;
      height: 100%;
      width: 100%;
      opacity: 0.36;
      position: absolute;
      left: 0;
      top: 0;
    }
    .inner {
      @include mq(pc) {
        align-items: center;
        justify-content: center;
      }
    }
  }
  #disaster-form {
    .btn {
      margin-top: 50px;
      @include mq {
        margin-top: 30px;
      }
    }
  }
  #disaster-chat {
    padding: 100px 0 50px;
    @include mq {
      padding: 50px 0;
    }
    .link-txt {
      margin-top: 30px;
    }
  }
  .history-chat {
    display: flex;
    flex-wrap: wrap;
    margin: -10px -10px 0;
    @include mq {
      margin: -10px 0 0;
    }
    li {
      display: inline-flex;
      padding: 10px 10px 0;
      @include mq {
        display: block;
        font-size: 1.4rem;
        padding: 10px 0 0;
        width: 50%;
      }
      .ttl {
        display: inline-block;
        vertical-align: middle;
        padding-right: 10px;
        @include mq {
          display: block;
          padding: 0 0 5px;
        }
        img {
          margin-right: 5px;
          width: 20px;
        }
      }
      .txt {
        display: inline-flex;
        max-width: 205px;
        @include mq {
          display: block;
          max-width: 100%;
        }
      }
      &:last-child {
        @include mq {
          width: 100%;
        }
      }
    }
  }
  .thread-list {
    .flex-box {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      @include mq(pc) {
        border-top: 1px solid $gray01;
        padding-top: 20px;
      }
    }
    .box-l {
      width: calc(100% - 378px);
      @include mq {
        margin-bottom: 20px;
        width: 100%;
      }
    }
    .box-r {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      @include mq {
        align-items: flex-end;
        width: 100%;
      }
      .remove-btn {
        @include mq {
          margin: 0 0 5px;
        }
      }
    }
    .history-chat {
      li {
        @include mq {
          border-bottom: 1px solid $gray01;
          padding-bottom: 5px;
        }
      }
    }
  }
  .key-box {
    input[type="text"] {
      background: $white;
      border: 1px solid $gray01;
      border-radius: 5px;
      color: $text-color;
      padding: 15.5px 20px;
      vertical-align: middle;
      margin-right: 15px;
      @include mq {
        padding: 10px 12px;
        height: 51px;
      }
      &.disabled {
        color: $color05;
      }
    }
    .key-txt {
      display: inline-block;
      margin-right: 15px;
      @include mq {
        display: block;
        margin: 0 0 5px;
      }        
    }
  }
  .remove-btn {
    text-align: left;
    margin: 0;
    a, label {
      background: $text-color;
      border-color: $text-color;
      color: $white;
      padding: 9px 35px 5px 15px;
      margin: 0;
      width: 90px;
      &:hover {
        background: $white;
        border-color: $gray01;
        color: $text-color;
        .arrow {
          &::before,
          &::after {
            background: $text-color;
          }
        }
      }
    }
  }
  .form-block {
    .textarea {
      &.h-01 {
        // height: 110px;
      }
      &.h-02 {
        height: 600px;
        @include mq {
          height: 250px;
        }
      }
    }
    input.w-01 {
      @include mq {
        width: 100%!important;
      }
    }
    .box-item {
      .btn label, .btn a {
        @include mq {
          width: 100%;
        }
      }
    }
    .photo-list {
      padding-top: 20px;
      li {
        border-bottom: 1px solid $gray01;
        padding-bottom: 10px;
        margin-bottom: 25px;
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        @include mq {
          padding-bottom: 10px;
          margin-bottom: 10px;
          display: block;
        }
        .photo-ttl {
          font-weight: bold;
          font-size: 1.8rem;
          @include mq {
            font-size: 1.6rem;
          }
        }
        .box-img {
          margin-left: 40px;
          height: 100px;
          width: 150px;
          overflow: visible;
          position: relative;
          @include mq {
            margin: 25px 0 0;
          }
          .delete-img {
            background: $text-color;
            border-radius: 50%;
            cursor: pointer;
            height: 30px;
            width: 30px;
            position: absolute;
            top: -15px;
            right: -15px;
            &::after {
              content: "";
              background: $white;
              -webkit-mask-image: url(../../img/common/cancel_icon.svg);
              mask-image: url(../../img/common/cancel_icon.svg);
              -webkit-mask-repeat: no-repeat;
              mask-repeat: no-repeat;
              -webkit-mask-size: 100% 100%;
              mask-size: 100% 100%;
              height: 15px;
              width: 15px;
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%,-50%);
            }
          }
        }
        .btn-file {
          margin-left: 40px;
          @include mq {
            margin: 10px 0 0;
          }
        }
        &:last-child {
          @include mq(pc) {
            border-bottom: none;
            padding-bottom: 0;
          }
        }
        .error-txt.sp {
          display: none;
          @include mq {
            display: block;
          }
        }
      }
    }
    .error-txt.pc {
      @include mq {
        display: none;
      }
    }
  }
  .thanks-block {
    padding: 0 0 200px;
    .thanks-txt {
      padding-bottom: 100px;
    }
  }
  .posts-ttl {
    padding-top: 0!important;
    margin-bottom: 60px;
    @include mq {
      margin-bottom: 30px;
    }
    h2 {
      padding: 0;
    }
    .history-chat {
      margin-top: 50px;
      @include mq {
        margin-top: 30px;
      }
    }
  }
  .accordion-ttl {
    background: $color-bg03;
    font-size: 3.2rem;
    cursor: pointer;
    padding: 36px 90px;
    position: relative;
    @include transition;
    @include mq {
      font-size: 2.4rem;
      padding: 20px 45px;
    }
    &::before {
      content: "";
      background-color: $color01;
      -webkit-mask-image: url(/img/common/edit_square.svg);
      mask-image: url(/img/common/edit_square.svg);
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
      -webkit-mask-size: 100% 100%;
      mask-size: 100% 100%;
      height: 27px;
      width: 27px;
      position: absolute;
      top: 44px;
      left: 45px;
      @include transition;
      @include mq {
        top: 25px;
        left: 20px;
        height: 20px;
        width: 20px;
      }
    }
    .icon {
      display: block;
      position: absolute;
      height: 30px;
      width: 30px;
      top: 45px;
      right: 45px;
      outline: none;
      @include mq {
        top: 22px;
        right: 20px;
      }
      &::before,
      &::after {
        content: "";
        background-color: $color01;
        height: 2px;
        width: 18px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        @include transition;
        @include mq {
          width: 14px;
        }
      }
      &::before {
        height: 18px;
        width: 2px;
        @include mq {
          width: 2px;
          height: 14px;
        }
      }
    }
    &.active {
      .icon {
        &::before {
          opacity: 0;
        }
      }
    }
    &._disabled {
      color: $color05;
      pointer-events: none;
      .attention-txt {
        display: inline-block;
        vertical-align: middle;
        color: $color04;
        font-size: 2rem;
        font-weight: bold;
        @include mq {
          font-size: 1.4rem;
          display: block;
          margin-left: -30px;
        }
      }
      &::before {
          background-color: $color05;
      }
      .icon {
        &::before,
        &::after {
          background-color: $color05;
        }
      }
    }
  }
  .cmn-sec .accordion-item {
    display: none;
    padding-top: 0;
    @include mq {
      padding-top: 10px;
    }
  }
  .post-block {
    padding: 80px 0 0;
    .post-number {
      border-bottom: 1px solid $gray01;
      font-weight: bold;
      padding-bottom: 25px;
      @include mq {
        border-bottom: none;
        padding-bottom: 0;
      }
      .num {
        color: $color01;
        font-size: 2.6rem;
        @include mq {
          font-size: 2.4rem;
        }
        &.red-txt {
          color: $color04;
        }
      }
      &.border-none{
        border-bottom: none;
        padding-bottom: 0;
      }
    }
    .post-list {
      li {
        border-bottom: 1px solid $gray01;
        padding: 30px 0 40px;
        @include mq {
          padding: 20px 0;
        }
        .description {
          font-size: 1.8rem;
          margin-bottom: 20px;
          @include mq {
            font-size: 1.4rem;
            margin-bottom: 10px;
          }
        }
        .flex-box {
          @include mq(pc) {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
          .box-l {            
            @include mq(pc) {
              display: flex;
              align-items: center;
            }
          }
          .account-id {
            display: flex;
            align-items: center;
            @include mq {
              font-size: 1.2rem;
              font-weight: bold;
            }
            img {
              margin: 0 5px 0 15px;
              display: inline-block;
              width: 25px;
              @include mq {
                margin: 0 5px 0 10px;
                width: 18px;
              }
            }
          }
          .date-txt {
            margin-left: 15px;
            color: $color05;
            @include mq {
              font-size: 1.2rem;
              margin: 5px 0 10px;
            }
          }
          .box-r {
            display: flex;
            align-items: center;
            justify-content: space-between;
            @include mq {
              align-items: end;
            }
            .key-txt {
              padding-right: 15px;
              @include mq {
                font-size: 1.2rem;
              }
            }
            > form {
              display: flex;
              align-items: center;
              justify-content: space-between;
              @include mq {
                margin-top: 5px;
              }
              input[type="text"] {
                width: 200px;
                @include mq {
                  height: 36px;
                  width: 121px;
                }
              }
            }
          }
        }
      }
    }
    .btn._black {
      margin-top: 50px;
      @include mq {
        margin-top: 30px;
      }
      a {
        width: 246px;
      }
    }
    .post-txt {
      padding: 50px 0 0;
      text-align: center;
    }
  }
  .disaster-sec {
    position: relative;
    overflow: hidden;
  }
  .slider-box {
    overflow: hidden;
    margin: 0 0 0 0;
    padding: 0 0 30px;
    @include mq {
      padding-bottom: 15px;
      margin: 0 -25px 0 0;
    }
    .box-img {
      a {
        display: block;
        position: relative;
        overflow: hidden;
        .icon {
          background: rgba($color: $gray01, $alpha: 0.66);
          border-radius: 50%;
          -webkit-border-radius: 50%;
          height: 26px;
          width: 26px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          right: 10px;
          bottom: 10px;
          z-index: 5;
          img {
            width: 11px;
          }
        }
        .img-hover {
          @include transition;
        }
        &:hover {
          .img-hover {
            transform: scale(1.1);
          }
        }
      }
    }
    .txt {
      font-size: 1.4rem;
      padding-top: 10px;
    }
    .swiper-slide {
      padding-right: 7px;
      max-width: 240px;
      box-sizing: content-box;
      @include mq {
        padding-right: 5px;
        max-width: 264px;
      }
    }    
  }
}
.modal {
  .img {
    text-align: center;
  }
  @media screen and (max-width: 1000px) {
    max-width: 90%;
  }
}
#modal-use {
  background: $white;
  text-align: left;
  .modal-inner {
    height: 700px;
    overflow-y: auto;
    padding: 60px;
    @include mq {
      height: 300px;
      padding: 30px 20px;
    }
    @include mq(msp) {
      height: 400px;
    }
  }
  .ttl01 {
    font-weight: 500;
    margin-bottom: 60px;
    @include mq {
      margin-bottom: 40px;
    }
    .jp-ttl {
      font-size: 4.8rem;
      span {
        font-size: 2rem;
        margin-left: 10px;
      }
      @include mq {
        font-size: 3.2rem;
      }
    }
    .en-ttl {
      font-family: $font-roboto;
      font-size: 1.6rem;
      text-transform: uppercase;
      display: block;
      color: $color01;
      padding-left: 25px;
      position: relative;
      @include mq {
        font-size: 1.2rem;
        padding-left: 15px;
      }
      &::before {
        content: "";
        background: $color01;
        height: 2px;
        width: 20px;
        position: absolute;
        left: 0;
        top: 10px;
        @include mq {
          height: 1px;
          width: 10px;
          top: 8px;
        }
      }
    }
  }
  .ttl02 {
    font-size: 3.2rem;
    font-weight: 500;
    margin-bottom: 30px;
    padding-left: 20px;
    position: relative;
    @include mq {
      margin-bottom: 20px;
      padding-left: 15px;
      font-size: 2.4rem;
    }
    &::before {
      content: "";
      background: $color01;
      height: 48px;
      width: 2px;
      position: absolute;
      left: 0;
      top: 0;
      @include mq {
        height: 36px;
      }
    }
  }
  .ttl03 {
    font-size: 2.4rem;
    font-weight: 500;
    padding: 0 0 20px 20px;
    position: relative;
    @include mq {
      font-size: 2rem;
      padding: 0 0 15px 20px;
    }
    &::before {
      content: "";
      background: $color01;
      height: 2px;
      width: 10px;
      position: absolute;
      left: 0;
      top: 15px;
      @include mq {
        height: 1px;
        top: 13px;
      }
    }
  }
  .ma-t50 {
    margin-top: 50px;
  }
  .txt {
    font-size: 1.6rem;
  }
  .use-block {
    + .use-block {
      margin-top: 80px;
    }
  }
  .img-list {
    margin-top: 30px;
    li {
      border: 1px solid $gray01;
      + li {
        margin-top: 20px;
      }
    }
  }
  .btn {
    a {
      padding: 12px 43px 12px 20px;
      width: 120px;
    }
    &.cancel-icon {
      a {
        .arrow::after {
          @include mq {
            display: none;
          }
        }
      }
    }
  }
}