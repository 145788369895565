.careers {
  .block {
    + .block {
      padding-top: 100px;
      @include mq {
        padding-top: 50px;
      }
    }
  }
  &.p-careers {
    .careers-list {
      li {
        border-bottom: 1px solid $gray01;
        display: flex;
        flex-wrap: wrap;
        padding: 30px 0;
        @include mq {
          padding: 20px 0;
        }
        .ttl {
          font-weight: bold;
          font-size: 1.8rem;
          width: 180px;
          @include mq {
            font-size: 1.4rem;
            margin-bottom: 5px;
            width: 100%;
          }
        }
        .txt {
          width: calc(100% - 200px);
          @include mq {
            width: 100%;
          }
        }
      }
    }
  }
  .box-txt {
    text-align: center;
    padding-bottom: 20px;
  }
}
