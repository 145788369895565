.blocker {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  z-index: 1;
  box-sizing: border-box;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
  text-align: center;
  z-index: 999;
}

.blocker:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -0.05em;
}

.blocker.behind {
  background-color: transparent;
}

.modal {
  display: none;
  vertical-align: middle;
  position: relative;
  z-index: 2;
  max-width: 880px;
  box-sizing: border-box;
  text-align: center;
  @include mq {
    width: 90%;
  }
  .img {
    display: inline-block;
    position: relative;
  }
}

.modal a.close-modal,
.modal a.close-modal2 {
  background: $white;
  border: 1px solid $gray01;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  height: 60px;
  width: 60px;
  position: absolute;
  top: -35px;
  right: -35px;
  display: block;
  text-indent: -9999px;
  &::before,
  &::after {
    content: "";
    background-color: $color01;
    height: 2px;
    width: 20px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%) rotate(45deg);
  }
  &::after {
    transform: translate(-50%,-50%) rotate(-45deg);
  }
  @include mq {
    height: 40px;
    width: 40px;
    top: -15px;
    right: -15px;
  }
  &:hover {
    opacity: .7;
  }
  &.close-modal {
    display: none;
  }
}

#conf-modal {
  a.close-modal {
    display: block;
  }
  .conf-box {
    background: $white;
    padding: 60px 30px;
    width: 640px;
    @include mq {
      padding: 40px 20px;
      width: 100%;
    }
    .ttl {
      font-size: 2rem;
      font-weight: bold;
      margin-bottom: 20px;
      @include mq {
        font-size: 1.8rem;
      }
    }
    .box-btn {
      @include mq(pc) {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 30px;
        .btn {
          margin: 0 10px;
          a {
            width: 230px;
          }
        }
      }
      @include mq {
        .btn + .btn {
          margin-top: 20px;
        }
      }
    }
  }
}


.modal-spinner {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  padding: 12px 16px;
  border-radius: 5px;
  background-color: #111;
  height: 20px;
}

.modal-spinner>div {
  border-radius: 100px;
  background-color: #fff;
  height: 20px;
  width: 2px;
  margin: 0 1px;
  display: inline-block;
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.modal-spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.modal-spinner .rect3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.modal-spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

@-webkit-keyframes sk-stretchdelay {

  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.5);
  }

  20% {
    -webkit-transform: scaleY(1);
  }
}

@keyframes sk-stretchdelay {

  0%,
  40%,
  100% {
    transform: scaleY(0.5);
    -webkit-transform: scaleY(0.5);
  }

  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}