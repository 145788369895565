@import "../mixin/mixin";

html,
body {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-size: 10px;
}

body {
  color: $text-color;
  font-family: $font-sans_serif;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.5;
  margin: 0;
  padding: 0;
  // @include ls(50);
  @include mq {
    font-size: 1.4rem;
  }
}

img {
  height: auto;
  width: auto;
  max-width: 100%;
  @include mq {
    width: 100%;
  }
}

/*  link 
-----------------------------------------------------------------------------*/
a {
  transition-property: background-color, opacity;
  transition-duration: 0.4s;
  transition-timing-function: ease;
  transition: all 0.2s;
}
a,
a:visited,
a:hover {
  color: $text-color;
  text-decoration: none;
}

a img {
  border: 0;
}

.clearfix:after {
  height: 0;
  display: block;
  clear: both;
  content: " ";
}

.pc {
  display: block;
  @include mq {
    display: none;
  }
}
.sp {
  display: none;
  @include mq {
    display: block;
  }
}

/*  reset 
-----------------------------------------------------------------------------*/
/* 
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com 
Twitter: @rich_clark
*/
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video,
input,
button,
textarea,
select {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  background: transparent;
  box-sizing: border-box;
}

ul,
ol {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

input,
select {
  vertical-align: middle;
}

// フォーム部品の完全リセット用
input {
  -webkit-appearance: none;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
select::-ms-expand {
  display: none;
}

input,
button,
select,
textarea {
  font-family: $font-sans_serif;
}

button,
input[type="submit"] {
  cursor: pointer;
  transition: 0.3s ease;
}

input::placeholder,
textarea::placeholder {
  color: #d6dadf;
}

/* IE */
input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #d6dadf;
}

/* Edge */
input::-ms-input-placeholder,
textarea::-ms-input-placeholder {
  color: #d6dadf;
}

img {
  vertical-align: top;
}

main {
  // IE11
  display: block;
}

[v-cloak] {
  display: none;
}
