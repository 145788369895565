.committee {
  .block + .block {
    margin-top: 100px;
    @include mq {
      margin-top: 80px;
    }
  }
  &.p-committee .p-nav .list .active-home a,
  &.p-activity-list .p-nav .list .active-activity-list a,
  &.p-news .p-nav .list .active-news a,
  &.p-report .p-nav .list .active-report a,
  &.p-members .p-nav .list .active-members a {
    background: $color01;
    color: $white;
    font-weight: bold;
  }
  &.p-committee {
    .committee-txt {
      font-size: 1.8rem;
      @include mq {
        font-size: 1.4rem;
      }
      a {
        color: $color01;
        font-weight: bold;
        &:hover {
          opacity: 0.7;
          text-decoration: underline;
        }
      }
    }
    .intro-box {
      align-items: center;
      display: flex;
      justify-content: space-between;
      @include mq {
        flex-wrap: wrap;
      }
      > div {
        @include mq {
          width: 100%;
        }
      }
      .box-img {
        position: relative;
        overflow: hidden;
        max-height: 449px;
        max-width: 570px;
        padding-left: 4%;
        width: 100%;
        @media screen and (min-width: 1200px) {
          max-height: 449px;
          height: 449px;
        }
        @include mq {
          margin-top: 50px;
          padding-left: 0;
        }
        img {
          object-fit: cover;
          height: 100%;
          width: 100%;
        }
      }
    }
    .link-txt {
      margin-top: 30px;
      text-align: right;
    }
  }
  &.p-zoom {
    .posts-ttl {
      .posts-date {
        display: flex;
        align-items: center;
        .en {
          font-size: 2rem;
          font-weight: bold;
          @include mq {
            font-size: 1.6rem;
          }
        }
        .category-txt {
          background: $color-bg02;
          display: inline-block;
          font-size: 1.4rem;
          margin-left: 10px;
          padding: 2px 10px 2px 10px;
          @include mq {
            font-size: 1.2rem;
          }
        }
      }
    }
    .cmn-sec {
      padding-top: 60px;
      @include mq {
        padding-top: 30px;
      }
      .box-txt {
        font-size: 1.8rem;
        @include mq {
          font-size: 1.4rem;
        }
        .link-txt {
          margin-top: 30px;
          @include mq {
            margin-top: 20px;
          }
        }
      }
      .video-box {
        margin: 50px auto 0;
        width: 70%;
        position: relative;
        overflow: hidden;
        padding-bottom: 38%;
        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
        @include mq {
          padding-bottom: 56%;
          width: 100%;
        }
      }
    }
  }
  &.p-report {
    .search-box {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      height: 60px;
      @include mq {
        height: 51px;
      }
      input[type="text"] {
        background: $white;
        border: 1px solid $gray01;
        border-radius: 5px 0 0 5px;
        -webkit-border-radius: 5px 0 0 5px;
        font-size: 2rem;
        color: $main-color;
        padding: 20px;
        height: 100%;
        width: calc(100% - 109px);
        @include mq {
          font-size: 1.4rem;
          padding: 15px;
          width: calc(100% - 93px);
        }
        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
          color: $color05;
        }
        &::-moz-placeholder { /* Firefox 19+ */
          color: $color05;
        }
        &:-ms-input-placeholder { /* IE 10+ */
          color: $color05;
        }
        &:-moz-placeholder { /* Firefox 18- */
          color: $color05;
        }
      }
      .search-btn {
        input {
          position: absolute;
          height: 0;
          width: 0;
          opacity: 0;
        }
        background: $color01;
        border: 1px solid $color01;
        border-left: none;
        border-radius:  0 5px 5px 0;
        -webkit-border-radius: 0 5px 5px 0;
        cursor: pointer;
        color: $white;
        font-size: 2rem;
        font-weight: bold;
        display: flex;
        align-items: center;
        height: 100%;
        width: 109px;
        padding-left: 20px;
        position: relative;
        @include transition;
        @include mq {
          font-size: 1.4rem;
          width: 93px;
        }
        img {
          margin-left: 5px;
          width: 18px;
          @include mq {
            width: 15px;
          }
        }
        &::after {
          content: "";
          background-color: $white;
          mask-image: url(../../img/committee/search_icon.svg);
          -webkit-mask-image: url(../../img/committee/search_icon.svg);
          -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
          -webkit-mask-size: 100% 100%;
          mask-size: 100% 100%;
          height: 18px;
          width: 18px;
          position: absolute;
          right: 22px;
          top: 50%;
          transform: translateY(-50%);
          @include transition;
        }
        &:hover {
          background: $white;
          border-color: $gray01;
          color: $color01;
          &::after {
            background-color: $color01;
          }
        }
      }
    }
    .flex-box {
      border-bottom: 1px solid $gray01;
      padding-bottom: 50px;
      @include mq {
        padding-bottom: 30px;
        .item + .item {
          margin-top: 30px;
        }
      }
      @include mq(pc) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .item {
          width: 48.5%;
        }
      }  
    }
    .check-list {
      display: flex;
      flex-wrap: wrap;
      margin: -6px -3px 0;
      li {
        margin: 6px 3px 0;
        .check-style {
          display: inline-block;
          position: relative;
          cursor: pointer;            
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
        }
        
        /* Hide the browser's default checkbox */
        .check-style input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
        }
        
        /* Create a custom checkbox */
        .checkmark {
          background: $white;
          border: 1px solid $gray01;
          border-radius: 50px;
          -webkit-border-radius: 50px;
          font-size: 1.6rem;
          display: block;
          padding: 14px 17px;
          @include transition;
          @include mq {
            font-size: 1.4rem;
            padding: 13px 17px;
          }
        }
        
        /* When the checkbox is checked, add a blue background */
        .check-style input:checked ~ .checkmark {
          background-color: $main-color;
          color: $white;
          font-weight: bold;
        }
        @include mq(pc) {
          .check-style:hover .checkmark {
            background-color: $main-color;
            color: $white;
            font-weight: bold;
          }
        }
        .checked {
          background: $main-color;
          border-radius: 50px;
          -webkit-border-radius: 50px;
          font-size: 1.6rem;
          font-weight: bold;
          cursor: pointer;
          color: $white;
          display: block;
          padding: 15px 50px 15px 20px;
          @include transition;
          position: relative;
          @include mq {
            font-size: 1.4rem;
          }
          .delete-icon {
            position: absolute;
            top: 50%;
            right: 20px;
            transform: translateY(-50%);
            height: 20px;
            width: 20px;
            cursor: pointer;
            @include transition;
            &::before,
            &::after {
              content: "";
              background: $white;
              height: 2px;
              width: 15px;
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%,-50%) rotate(45deg);
              @include transition;
            }
            &::after {
              transform: translate(-50%,-50%) rotate(-45deg);
            }
          }
        }
      }
    }
    .report-txt {
      text-align: center;
    }
    .btn {
      &._black label,
      &._black a {
        padding: 15px 20px;
        width: 300px;
        text-align: center;
        justify-content: center;
        &::after {
          display: none;
        }
      }
    }
    .box-btn {
      @include mq {
        padding-top: 10px;
      }
      .btn {
        @include mq(pc) {
          margin-top: 30px;
        }
        &._black label,
        &._black a {
          width: 160px;
        }
      }
    }
    .result-txt {
      border-bottom: 1px solid $gray01;
      font-weight: bold;
      padding: 20px 0;
      margin-bottom: 20px;
      .en {
        font-size: 2.6rem;
        color: $color01;
        margin-right: 5px;
        @include mq {
          font-size: 2.4rem;
          margin-right: 2px;
        }
      }
    }
    .box-img {
      margin-top: 50px;
      text-align: center;
      @include mq {
        margin-top: 30px;
      }
    }
    .posts-link {
      border-bottom: 1px solid $gray01;
      padding-bottom: 40px;
    }
  }
  &.p-members {    
    .box-ttl {
      > p {
        border: 1px solid $gray01;
        font-size: 1.4rem;
        padding: 5px 10px;
        text-align: center;
        @include mq {
          display: inline-block;
          padding: 3px 10px;
          margin-bottom: 10px;
        }
      }
      @include mq(pc) {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        > p {
          order: 2;
          font-size: 1.8rem;
          padding: 2px 10px;
          margin: 0 0 30px 20px;
        }
      }
    }
  }
  .sns-list02 {
    display: flex;
    padding-top: 80px;
    @include mq {
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: -35px;
    }
    li {
      height: 50px;
      width: 25%;
      @include mq {
        margin-top: 35px;
        height: 40px;
        width: 50%;
      }
      a {
        border-left: 1px solid $gray01;
        display: block;
        height: 100%;
        width: 100%;
        text-align: center;
        &:hover {
          opacity: 0.7;
        }
        @include mq {
          img {
            height: 40px;
            width: auto;
          }
        }
      }
      &:last-child {
        a {
          border-right: 1px solid $gray01;
        }
      }
    }
  }
}

