.contest {
  .main-img {
    margin-bottom: 60px;
  }
  .list-txt {
    font-size: 1.4rem;
    margin-bottom: 20px;
    dt {
      font-weight: bold;
      &:nth-child(n+2) {
        margin-top: 5px;
      }
    }
    dd {
      margin: -20px 0 0 70px;
    }
  }
  .txt {
    font-size: 1.4rem;
    font-weight: bold;
    padding-bottom: 20px;
  }
  .lead-txt {
    padding-bottom: 30px;
    @include mq {
      padding-bottom: 20px;
    }
  }
  .department-box {
    + .department-box {
      margin-top: 100px;
      @include mq {
        margin-top: 80px;
      }
    }
  }
  .department-ttl {
    background: $color01;
    color: $white;
    font-size: 2.4rem;
    font-weight: bold;
    padding: 5px 10px;
    margin-bottom: 30px;
    @include mq {
      font-size: 2rem;
      margin-bottom: 20px;
    }
  }
  .results-box01 {
    margin-bottom: 50px;
    @include mq {
      margin-bottom: 30px;
    }
    ul {
      li {
        border-bottom: 1px solid $gray01;
        padding-bottom: 50px;
        @include mq {
          padding-bottom: 30px;
        }
        + li {
          margin-top: 50px;
          @include mq {
            margin-top: 30px;
          }
        }
        .flex-box {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
        }
        .box-img {
          background: $color-bg01;
          height: 315px;
          width: 39.5%;
          position: relative;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          img {
            object-fit: cover;
            max-height: 100%;
            width: auto;
          }
          @include mq {
            height: 223px;
            margin-bottom: 20px;
            width: 100%;
          }
        }
        .box-txt {
          width: 58%;
          @include mq {
            width: 100%;
          }
        }
      }
    }
  }
  .results-box02 {
    + .results-box02 {
      border-top: 1px solid $gray01;
      padding-top: 50px;
      margin-top: 50px;
      @include mq {
        padding-top: 30px;
        margin-top: 30px;
      }
    }
    .ttl02 {
      &:nth-child(n+2) {
        margin-top: 50px;
        @include mq {
          margin-top: 30px;
        }
      }
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      margin: -50px -1% 0;
      @include mq {
        margin: -30px -1% 0;
      }
      li {
        padding: 50px 1% 0;
        width: 33.333%;
        @include mq {
          padding: 30px 0 0;
          width: 100%;
        }
        .img {
          background: $color-bg01;
          height: 250px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 20px;
          position: relative;
          overflow: hidden;
          text-align: center;
          @include mq {
            height: 223px;
          }
          &.bg {
            background: $color-bg01;
          }
          img {
            object-fit: cover;
            max-height: 100%;
            width: auto;
          }
        }
      }
    }
  }
  .box-btn2 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 50px;
    margin: 0 -10px;
    @include mq {
      margin: 0 -1%;
    }
    .btn {
      margin: 10px;
      a {
        height: 100%;
        width: 100%;
      }
    }
  }
  .box-btn {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 50px;
    margin: 0 -10px;
    @include mq {
      margin: 0 -1%;
    }
    .btn {
      margin: 0 10px;
      width: 180px;
      @include mq {
        width: 48%;
        margin: 0 1%;
      }
      a {
        height: 100%;
        width: 100%;
      }
    }
  }
  &.p-senryu_contest {
    .results-box01 {
      ul {
        li {
          @include mq(pc) {
            border-bottom: none;
            padding-bottom: 0;
          }
          .box-img {
            background: none;
            border: 1px solid $gray01;
            height: 386px;
            @include mq {
              height: 240px;
            }
          }
        }
      }
    }
    .results-box02 {
      ul {
        &._border {
          @include mq {
            border-bottom: 1px solid $gray01;
            padding-bottom: 30px;
          }
        }
        .img {
          background: none;
          border: 1px solid $gray01;
        }
      }
    }
  }
  &.p-cm_contest {
    .flex-box {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 100px;
      @include mq {
        margin-bottom: 50px;
      }
      > div {        
        width: 48%;
        img {
          width: 100%;
        }
        span {
          font-size: 1.4rem;
          display: block;
          padding-top: 10px;
        }
      }
    }
    .video-item {
      h4 {
        padding-bottom: 0;
      }
      + .video-item {
        border-top: 1px solid $gray01;
        padding-top: 50px;
        margin-top: 50px;
        @include mq {
          padding-top: 30px;
          margin-top: 30px;
        }
      }
      .box-video {
        position: relative;
        overflow: hidden;
        margin-bottom: 20px;
        padding-bottom: 56.25%;
        width: 100%;
        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
      ul {
        @include mq(pc) {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          li {
            width: 48%;
          }
        }
        @include mq {
          li + li {
            margin-top: 30px;
          }
        }
      }
    }
    .list {
      display: flex;
      flex-wrap: wrap;
      margin: 90px -1% 0;
      @include mq {
        margin: 70px -1% 0;
      }
      li {
        margin: 0;
        padding: 10px 1% 0;
        width: 25%;
        @include mq {
          text-align: left;
          width: 50%;
        }
        a {
          width: 100%;
        }
        &.active {
          a {
            background: #fff;
            border-color: #DBDBDB;
            color: #0081CC;
            pointer-events: none;
            cursor: auto;
          }
        }
      }
    }
  }
  &.p-theme {
    .txt01 {
      font-size: 2.4rem;
      margin-bottom: 20px;
      @include mq {
        font-size: 2rem;
      }
    }
    .link-txt a {
      @include mq {
        font-size: 1.6rem;
      }
    }
    .mb-30 {
      margin-bottom: 30px;
    }
    .mt-10 {
      margin-top: 10px;
    }
    .block {
      margin-top: 50px;
    }
    .structure-list {
      display: flex;
      flex-wrap: wrap;
      margin: -10px -5px 20px;
      @include mq {
        margin: -6px -3px 20px;
      }
      li {
        border: 1px solid $gray01;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 10px 5px 0;
        padding: 0 5px;
        height: 80px;
        width: 80px;
        @include mq {
          margin: 6px 3px 0;
          padding: 0 5px;
          height: 78px;
          width: 78px;
        }
      }
    }
    .flow-list {
      li {
        background: $color-bg03;
        padding: 25px 30px 25px 130px;
        position: relative;
        @include mq {
          padding: 20px;
        }
        + li {
          margin-top: 20px;
          &::after {
            content: "";
            background: $color01;
            height: 50px;
            width: 8px;
            position: absolute;
            left: 60px;
            top: -30px;
            z-index: -1;
            @include mq {
              left: 42px;
            }
          }
        }
        .box-ttl {
          margin-bottom: 10px;
          @include mq {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin-bottom: 15px;
          }
        }
        .box-txt {
          .small-txt {
            font-size: 1.4rem;
            @include mq {
              font-size: 1.2rem;
            }
          }
        }
        h5 {
          font-size: 2.4rem;
          font-weight: bold;
          @include mq {
            margin-left: 15px;
            width: calc(100% - 73px);
          }
        }
        .step-txt {
          background: $color01;
          border-radius: 50%;
          -webkit-border-radius: 50%;
          color: $white;
          font-size: 1.2rem;
          font-family: $font-roboto;
          display: flex;
          flex-direction: column;
          justify-content: center;
          text-align: center;
          position: absolute;
          left: 25px;
          top: 50%;
          transform: translateY(-50%);
          height: 82px;
          width: 82px;
          @include mq {
            font-size: 1.4rem;
            position: static;
            left: auto;
            top: auto;
            transform: translateY(0);
            height: 53px;
            width: 53px;
          }
          span {
            font-size: 3.4rem;
            font-weight: bold;
            line-height: 1;
            display: block;
            @include mq {
              font-size: 2.2rem;
              line-height: 0.8;
            }
          }
        }
      }
    }
    .scroll-box {
      @include mq {
        position: relative;
        overflow-x: auto;
      }
      &.scroll-hint.is-left-scrollable,
      &.scroll-hint.is-right-scrollable {
        background: none;
      }
    }
    .box-tbl02 {
      border: 1px solid $gray01;
      border-radius: 5px;
      -webkit-border-radius: 5px;
      position: relative;
      overflow: hidden;
      width: 670px;
      table {          
        width: 100%;
        th {
          background: $color-bg03;
          border-bottom: 1px solid $gray01;
          border-right: 1px solid $gray01;
          font-weight: normal;
          padding: 10px 15px;
          text-align: left;
          @include mq {
            padding: 10px;
          }
          &.last {
            border-right: none;
          }
        }
        td {
          border-bottom: 1px solid $gray01;
          border-right: 1px solid $gray01;
          padding: 10px 15px;
          @include mq {
            padding: 10px;
          }
          .link-txt {
            a {
              font-size: 1.6rem;
              padding-right: 40px;
              &::before {
                height: 30px;
                width: 30px;
              }
              .arrow {
                height: 30px;
                width: 30px;
                &::before,
                &::after {
                  height: 8px;
                  width: 10px;
                }
              }
              &:hover {
                .arrow {
                  &::after {
                    margin-left: -12px;
                  }
                }
              }
            }
            &.pdf-icon {
              a {
                .arrow {
                  &::before,
                  &::after {
                    height: 15px!important;
                    width: 15px!important;
                  }
                  &::before {
                    margin-left: -4px;
                  }
                }
                &:hover {
                  .arrow {
                    &::after {
                      margin-left: -6px;
                    }
                  }
                }
              }
            }
          }
          &.last {
            border-right: none;
          }
          a.link {
            img {
              margin-left: 10px;
              vertical-align: middle;
              width: 15px;
            }
          }
        }
        tr {
          &:last-child {
            th,td {
              border-bottom: none;
            }
          }
        }
      }
    }
    .txt-list {
      li {
        position: relative;
        padding-left: 15px;
        &::after {
          content: "";
          background: $main-color;
          border-radius: 50%;
          height: 5px;
          width: 5px;
          position: absolute;
          left: 0;
          top: 8px;
        }
        + li {
          margin-top: 5px;
        }
        .red-txt {
          color: $color04;
        }
        .blue-txt {
          color: $color01;
        }
      }
    }
    .box-btn .btn {
      @include mq {
        width: 55%;
      }
    }
  }
}
