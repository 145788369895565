.technology {
  &.p-technology {
    .box-item {
      + .box-item {
        margin-top: 20px;
      }
    }
    .box-txt {
      font-size: 1.8rem;
      @include mq {
        font-size: 1.4rem;
      }
    }
    .technology-block {
      padding: 50px 0 0;
      @include mq {
        padding: 30px 0 0;
      }
      .slider-box {
        overflow: hidden;
        .box-img {
          a {
            display: block;
            position: relative;
            overflow: hidden;
            .icon {
              background: rgba($color: $gray01, $alpha: 0.66);
              border-radius: 50%;
              -webkit-border-radius: 50%;
              height: 26px;
              width: 26px;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              position: absolute;
              right: 10px;
              bottom: 10px;
              z-index: 5;
              img {
                width: 11px;
              }
            }
            .img-hover {
              @include transition;
            }
            &:hover {
              .img-hover {
                transform: scale(1.1);
              }
            }
          }
        }
        .txt {
          font-size: 1.4rem;
          padding-top: 10px;
        }
        .swiper-slide {
          padding-right: 7px;
          max-width: 240px;
          box-sizing: content-box;
          @include mq {
            padding-right: 5px;
            max-width: 264px;
          }
        }
        
      }
      .ma-t30 {
        margin-top: 30px;
      }
      .link {
        img {
          vertical-align: sub;
        }
      }
    }
    .modal {
      .popup-txt {
        margin-top: 20px;
        text-align: center;
        span {
          display: inline-block;
          background: $color-bg01;
          border-radius: 24px;
          -webkit-border-radius: 24px;
          color: $white;
          font-size: 1.8rem;
          padding: 10px 20px;
          text-align: center;
          @include mq {
            font-size: 1.4rem;
          }
        }        
      }
    }
  }
}
