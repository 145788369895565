.header {
  height: $pc-header-height;
  background: $white;
  @include transition;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 999;
  @include mq(sph) {
    height: $sp-header-height;
  }
  .header-inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }
  #logo {
    padding-left: 60px;
    width: 364px;
    a {
      display: flex;
    }
    @include mq(lpc) {
      padding-left: 20px;
      width: 250px;     
    }
    @include mq(sph) {
      padding-left: 20px;
      width: 50%;
      max-width: 200px;
    }
    img {
      &.sp-logo {
        display: none;
      }
    }
  }
  .h-menu {
    background: $main-color;
    color: $white;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: absolute;
    left: 0;
    top: 0;
    height: 100vh;
    overflow: auto;
    width: 100%;
    z-index: 98;
    @include transition;
    opacity: 0;
    visibility: hidden;
    transform: translateY(1rem);
    @include mq(sph) {
      top: $sp-header-height;
      height: calc(100vh - 60px);
      padding: 0 0 100px;
      .pc {
        display: none;
      }
      .sp {
        display: block;
      }
    }
    a {
      color: $white;
    }
    .menu-l {
      background: url(../../img/common/menu_bg.webp) no-repeat center center;
      background-size: cover;
      color: $white;
      padding: 125px 3%;
      display: flex;
      flex-direction: column;
      justify-content: end;
      width: 40%;
      max-width: 670px;
      h2 {
        font-size: 3.6rem;
        font-weight: 500;
        margin-bottom: 40px;
        text-shadow: 0px 4px 3px rgba(0,0,0,0.4),
             0px 8px 13px rgba(0,0,0,0.1),
             0px 18px 23px rgba(0,0,0,0.1);
      }
      > p {
        font-size: 1.4rem;
        text-shadow: 0px 4px 3px rgba(0,0,0,0.4),
             0px 8px 13px rgba(0,0,0,0.1),
             0px 18px 23px rgba(0,0,0,0.1);
      }
      @include mq(sph) {
        display: none;
      }
    }
    .menu-r {
      display: flex;
      align-items: center;
      padding: 0 5%;
      width: 60%;
      @media print, screen and (min-width:1670px) {
        width: calc(100% - 670px);
      }
      .menu-inner {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: -30px;
        width: 100%;
        @include mq(sph) {
          margin-bottom: 0;
        }
      }
      @include mq(sph) {
        padding: 30px 20px 0;
        width: 100%;
      }
      .col {
        width: 30%;
        @include mq(sph) {
          width: 100%;
        }
      }
      .g-nav02 {
        > ul {
          > li {            
            font-size: 2.2rem;
            letter-spacing: 0;
            @include mq(lpc) {
              font-size: 1.8rem;
              
            }
            @include mq(sph) {
              font-size: 1.6rem;
              .pc {
                display: none;
              }
              .sp {
                display: block;
              }
            }
            > a {
              border-bottom: 1px solid $gray02;
              display: block;
              padding: 15px 0;
              @include mq(sph) {
                padding: 15px 10px;
              }
              &:hover {
                color: $color01;
              }
            }
            &.home-link {
              margin-bottom: 30px;
              @include mq(sph) {
                margin-bottom: 0;
              }
            }
            span {
              display: none;
            }
            @include mq(sph) {
              position: relative;
              > a {
                width: 100%;
              }
              span {
                border-bottom: 1px solid $gray02;
                font-size: 1.6rem;
                display: block;
                padding: 15px 10px;
                width: 100%;
                cursor: pointer;
                position: relative;
                @include transition;
                &::before,
                &::after {
                  content: "";
                  background: $white;
                  height: 2px;
                  width: 12px;
                  position: absolute;
                  top: 50%;
                  right: 20px;
                  transform: translateY(-50%);
                  @include transition;
                }
                &::after {
                  transform: translateY(-50%) rotate(90deg);
                }
              }
            }
            &.active {
              span,
              > a {
                color: $color01;
              }
              span::after {
                opacity: 0;
              }
            }
            > ul {
              display: block;
              margin-bottom: 30px;
              @include mq(sph) {
                display: none;
                padding: 0 15px;
                margin: 10px 0;
              }
              li {
                font-size: 1.6rem;
                margin-top: 15px;
                letter-spacing: 0;
                @include mq(sph) {
                  margin-top: 0;
                }
                a {
                  @include mq(sph) {
                    display: block;
                    padding: 10px 0;
                  }
                  &:hover {
                    color: $color01;
                  }
                }
              }
            }
          }
        }
      }
      .box {
        @include mq(sph) {
          display: flex;
          flex-wrap: wrap;
          .h-txt {
            margin: 100px 0 20px;
            order: 2;
            display: block;
            width: 100%;
            h2 {
              font-size: 2rem;
              font-weight: 500;
              margin-bottom: 20px;
            }
            p {
              font-size: 1.2rem;
            }
          }
          .copy-right {
            order: 5;
            color: $color02;
            font-size: 1.2rem;
            font-family: $font-roboto;
            margin-top: 10px;
            width: 100%;
          }
        }        
      }
      .h-nav02 {
        margin-top: 30px;
        @include mq(sph) {
          margin-top: 40px;
          order: 4;
          width: 100%;
          ul {
            display: flex;
            flex-wrap: wrap;
            margin: -10px -5px 0;
          }
        }
        li {
          a {
            border-bottom: 1px solid $gray02;
            display: block;
            font-size: 2.2rem;
            padding: 15px 0;
            @include mq(lpc) {
              font-size: 1.8rem;
             
            }
            @include mq(sph) {
              border: none;
              font-size: 1.2rem;
              padding: 0;
            }
            &:hover {
              color: $color01;
            }
          }
          @include mq(sph) {
            margin: 10px 5px 0;
          }
        }
      }
    }
    .login-btn02 {
      margin: 40px 0 0;
      @include mq(sph) {
        order: 1;
        width: 100%;
      }
      a {
        border: 1px solid $color01;
        background: $color01;
        border-radius: 50px;
        color: $white;
        font-size: 1.6rem;
        font-weight: bold;
        display: inline-flex;
        align-items: center;
        padding: 0 60px 0 30px;
        height: 64px;
        width: 100%;
        text-align: center;
        position: relative;
        @include mq(lpc) {
          font-size: 1.4rem;
          padding: 0 60px 0 20px;
          @include mq(sph) {
            font-size: 1.6rem;
          }
        }
        &::before {
          content: "";
          background-color: $white;
          -webkit-mask-image: url(../../img/common/login_icon.svg);
          mask-image: url(../../img/common/login_icon.svg);
          -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
          -webkit-mask-size: 100% 100%;
          mask-size: 100% 100%;
          height: 18px;
          width: 18px;
          position: absolute;
          top: 50%;
          right: 30px;
          transform: translateY(-50%);
          @include mq(sph) {
            left: auto;
            right: 30px;
            top: 50%;
            transform: translate(0,-50%);
            height: 21px;
            width: 16px;
          }
        }
        &:hover {
          background: $white;
          color: $color01;
          &::before {
            background-color: $color01;
          }
        }
      }
    }
    &.opened {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
      transition: opacity 0.4s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    .member-info {
      display: none;
    }
  }
  #sitesearch {
    margin-left: 10px;
    width: 155px;
    position: relative;
    @include mq(lpc) {
      // width: 200px;
    }
    @include mq(sph) {
      display: block;
      margin: 0 0 25px;
      width: 100%!important;
    } 
    .kw-txt {
      background: $white;
      border: 1px solid $gray01;
      border-radius: 5px;
      color: $main-color;
      font-size: 1.2rem;
      padding: 0 12px;
      width: 100%;
      height: 30px;
      @include mq(sph) {
        font-size: 1.6rem;
        height: 60px;
        width: 100%;
      }
    }
    .s-btn {
      top: 8px;
      right: 10px;
      position: absolute;
      @include mq(sph) {
        top: 20px;
        right: 20px;
        width: 18px;
      }
    }
    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: $color02;
    }
    ::-moz-placeholder { /* Firefox 19+ */
      color: $color02;
    }
    :-ms-input-placeholder { /* IE 10+ */
      color: $color02;
    }
    :-moz-placeholder { /* Firefox 18- */
      color: $color02;
    }
  }
  .h-box01 {
    margin-right: 20px;
    .box-flex {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      padding-top: 18px;
    }
    @include mq(sph) {
      display: none;
    }
  }
  .h-nav {
    align-items: center;
    @include mq(sph) {
      display: none;
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      li {
        a {
          font-size: 1.2rem;
          display: block;
          position: relative;
          &::after {
            content: "";
            background: $main-color;
            height: 1px;
            width: 100%;
            position: absolute;
            left: 0;
            bottom: 0;
            transform: scale(0, 1);
            transform-origin: right top; 
            transition: transform .3s;
          }
          &:hover {
            &::after {
              transform-origin: left top;
              transform: scale(1, 1);
            }
          }
        }
        + li {
          margin-left: 12px;
        }
      }
    }
  }
  .g-nav {
    @include mq(sph) {
      margin: 0;
    }
    > ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      > li {
        padding: 10px 0 18px;
        @include mq(sph) {
          width: 100%;
        }
        + li {
          padding-left: 12px;
        }
        > a {
          font-size: 1.6rem;
          display: inline-block;
          position: relative;
          @include mq(lpc) {
            font-size: 1.4rem;
          }
          &::after {
            content: "";
            background: $color01;
            height: 2px;
            width: 100%;
            position: absolute;
            left: 0;
            bottom: 0;
            transform: scale(0, 1);
            transform-origin: right top; 
            transition: transform .3s;            
          }
          &:hover,
          &.active {
            &::after {
              transform-origin: left top;
              transform: scale(1, 1);
            }
          }
        }
      }
    }
    .sub-nav {
      background: $white;
      padding: 50px 0;
      position: absolute;
      top: $pc-header-height;
      left: 0;
      width: 100%;
      z-index: -1;
      // display: none;
      opacity: 0;
      pointer-events: none;
      transform: translateY(1rem);
      transition: opacity 0.4s cubic-bezier(0.19, 1, 0.22, 1), transform 0.4s cubic-bezier(0.19, 1, 0.22, 1);
      @include transition;
      @include mq(sph) {
        display: none;
      }
      .nav-ttl {
        a {
          display: inline-block;
          font-size: 2.2rem;
          padding: 10px 50px 20px 0;
          position: relative;
          &::before {
            content: "";
            background: $color01;
            border: 1px solid $color01;
            border-radius: 50%;
            -webkit-border-radius: 50%;
            height: 40px;
            width: 40px;
            position: absolute;
            top: 46%;
            right: 0;
            transform: translateY(-50%);
            @include transition;
          }
          &::after {
            content: "";
            background-color: $gray01;
            height: 1px;
            width: 100%;
            position: absolute;
            left: 0;
            bottom: 0;
          }
          .arrow {
            background: transparent;
            border-radius: 50%;
            -webkit-border-radius: 50%;
            display: block;
            height: 40px;
            width: 40px;
            position: absolute;
            top: 46%;
            right: 0;
            transform: translateY(-50%);
            overflow: hidden;
            &::before,
            &::after {
              content: "";
              background-color: $white;
              -webkit-mask-image: url(../../img/common/arrow_01.svg);
              mask-image: url(../../img/common/arrow_01.svg);
              -webkit-mask-repeat: no-repeat;
              mask-repeat: no-repeat;
              -webkit-mask-size: 100% 100%;
              mask-size: 100% 100%;
              height: 12px;
              width: 16px;
              position: absolute;
              left: 12px;
              top: 50%;
              transform: translateY(-50%);
            }
            &::after {
              left: -10px;
              transform: translate(-200%, -50%);
            }
          }
          &:hover {
            &::before {
              background: $white;
              border: 1px solid $gray01;
            }
            .arrow {
              &::before,
              &::after {
                background-color: $color01;
              }
              &::before {
                animation: animate-arrow01 0.5s alternate;
              }
              &::after {
                animation: animate-arrow02 0.5s alternate;
              }
            }
            
          }
        }
      }
      ul {
        display: flex;
        flex-wrap: wrap;
        padding-top: 10px;
        margin: 0 -10px;
        li {
          padding: 20px 10px 0;
          width: 25%;
          a {
            display: block;
            padding: 0 20px 10px 0;
            width: 100%;
            position: relative;
            &::before {
              content: "";
              background-color: $gray01;
              height: 1px;
              width: 100%;
              position: absolute;
              left: 0;
              bottom: 0;
              @include transition;
              z-index: 0;
            }
            .arrow {
              background: transparent;
              position: absolute;
              height: 20px;
              width: 20px;
              right: 0;
              top: 6px;
              overflow: hidden;
              &::before,
              &::after {
                content: "";
                background: url(/img/common/arrow_02.svg) no-repeat left top;
                background-size: 100% 100%;
                height: 12px;
                width: 16px;
                position: absolute;
                left: 0;
                top: 0;
                transform: rotate(90deg);
              }
              &::after {
                left: -20px;
                transform: translate(-100%,0) rotate(90deg);
              }
            }
            &:hover {
              color: $color01;
              .arrow {
                &::before {
                  animation: nav-arrow01 0.5s alternate;
                }
                &::after {
                  animation: nav-arrow02 0.5s alternate;
                }
              }
            }
          }
        }
      }
      &.active {
        z-index: 999;
        opacity: 1;
        transition: opacity 0.4s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
        pointer-events: auto;
        transform: translateY(0);
      }
      @keyframes nav-arrow01 {
        0% {
          left: 0px;
        }
        100% {
          left: 30px;
        }
      }
      @keyframes nav-arrow02 {
        0% {
          left: -20px;
        }
        100% {
          left: 16px;
        }
      }
    }
  }
  .sns-list {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin: 50px 0 0;
    @include mq(sph) {
      order: 3;
      margin: 0;
    }
    li {
      & + li {
        margin-left: 20px;
        @include mq(sph) {
          margin-left: 20px;
        }
      }
      a {
        display: inline-block;
        color: $white;
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
  
  .h-right {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    .login-btn01 {
      height: $pc-header-height;
      transition: all 0.3s;
      width: 103px;
      text-align: center;
      @include mq(lpc) {
        width: 70px;
      }
      @include mq(sph) {
        height: $sp-header-height;
      }
      @include mq {
        width: 70px;
      }
      a {
        border: 1px solid  $color01;
        background-color: $color01;
        color: $white;
        font-size: 1.4rem;
        font-weight: bold;
        height: 100%;
        width: 100%;
        display: block;
        padding-top: 45px;
        position: relative;
        @include mq(lpc) {
          font-size: 1.2rem;
        }
        @include mq(sph) {
          font-size: 1.1rem;
          line-height: 1.2;
          padding-top: 33px;
          .pc {
            display: none;
          }
        }
        &::before {
          content: "";
          background-color: $white;
          -webkit-mask-image: url(../../img/common/login_icon.svg);
          mask-image: url(../../img/common/login_icon.svg);
          -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
          -webkit-mask-size: 100% 100%;
          mask-size: 100% 100%;
          height: 16px;
          width: 16px;
          position: absolute;
          left: 50%;
          top: 18px;
          transform: translateX(-50%);
          @include mq(sph) {
            top: 10px;
          }
          @include mq {
            height: 15px;
            width: 15px;
          }
        }
        &:hover {
          background-color: $white;
          border-color: $color06;
          color: $color01;
          &::before {
            background-color: $color01;
          }
        }
      }
    }
    #menu-btn {   
      position: relative;
      height: $pc-header-height;
      width: 100px;
      @include mq(lpc) {
        width: 70px;
      }
      @include mq(sph) {
        height: $sp-header-height;
      }
      @include mq {
        width: 70px;
      }
      span {
        background: $main-color;
        border: 1px solid $main-color;
        cursor: pointer;
        color: $white;
        font-size: 1.4rem;
        font-weight: bold;
        display: block;
        padding-top: 55px;
        height: 100%;
        width: 100%;
        text-align: center;
        transition: all 0.3s;
        opacity: 1;
        position: absolute;
        left: 0;
        top: 0;
        @include mq(lpc) {
          font-size: 1.2rem;
        }
        @include mq(sph) {
          font-size: 1.1rem;
          padding-top: 32px;
        }            
        &::before,
        &::after {
          content: "";
          background: $white;
          height: 1px;
          width: 40px;
          position: absolute;
          left: 50%;
          top: 30px;
          transform: translateX(-50%);
          transition: all 0.3s;
          @include mq(sph) {
            width: 30px;
            top: 13px;
          }
        }
        &::after {
          top: 40px;
          @include mq(sph) {
            top: 23px;
          }
        }
        &.close-txt {
          opacity: 0;
          z-index: -1;
          &::before {
            transform: translateX(-50%) rotate(25deg);
            opacity: 0;
            @include mq {
              transform: translateX(-50%) rotate(37deg);
            }
          }
          &::after {
            transform: translateX(-50%) rotate(-25deg);
            opacity: 0;
            @include mq {
              transform: translateX(-50%) rotate(-37deg);
            }
          }
        }
        @include mq(pc) {
          &.open-txt:hover {
            background: $white;
            border-color: $color06;
            color: $color01;
            &::before,
            &::after {
              background: $color01;
            }
          }
        }
      }
    }
    &.active {
      .login-btn01  {
        z-index: 99;
        a {
          background: $main-color;
          border-color: $main-color;
          &:hover {
            color: $color01;
            &::before {
              background-color: $color01;
            }
          }
        }
      }
      #menu-btn {
        z-index: 99;
        .open-txt {
          opacity: 0;
        }
        .close-txt {          
          opacity: 1;
          z-index: 5;
          &::before,
          &::after {
            opacity: 1;
            top: 30px;
            @include mq(sph) {
              top: 15px;
            }
          }
          @include mq(pc) {
            &:hover {
              color: $color01;
              &::before,
              &::after {
                background: $color01;
              }
            }
          }
        }
      }
    }
  }
  .over-nav {
    background: rgba($color: $black, $alpha: 0.5);
    position: fixed;
    left: 0;
    top: 100px;
    height: 100%;
    width: 100%;
    display: none;
    &.show {
      display: block;
    }
    @include mq(sph) {
      display: none;
    }
  }
  &._02 {
    .h-menu {
      @include mq(pc) {
        background: none;
        display: block;
        position: static;
        height: auto;
        overflow: hidden;
        padding-bottom: 0;
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
      }
      .disaster-menu {
        @include mq(pc) {
          margin-right: 20px;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          li {
            a {
              color: $text-color;
              font-size: 1.6rem;
              position: relative;
            }
            + li {
              margin-left: 20px;
            }
            &:not(:last-child) {
              a {
                padding: 5px 0;
                &::after {
                  content: "";
                  background: #0081CC;
                  height: 2px;
                  width: 100%;
                  position: absolute;
                  left: 0;
                  bottom: 0;
                  transform: scale(0, 1);
                  transform-origin: right top;
                  transition: transform .3s;
                }
                &:hover::after {
                  transform-origin: left top;
                  transform: scale(1, 1);
                }
              }
            }
            &.link-txt {
              a {
                padding: 10px 50px 10px 0;
              }
            }
          }
        }
        @include mq {
          padding: 20px;
          width: 100%;
          li {
            border-bottom: 1px solid $gray02;
            a {
              font-size: 1.6rem;
              padding: 15px 10px;
              display: block;
            }
            &.link-txt {
              a {
                display: inline-block;
                color: $white!important;
                padding: 15px 50px 15px 10px;
                font-size: 1.6rem;
              }
            }
          }
        }       
      }
      .copy-right {
        font-size: 1.2rem;
        color: $color06;
        line-height: 1.2;
        position: absolute;
        left: 0;
        bottom: 20px;
        padding: 0 20px;
        width: 100%;
        @include mq(pc) {
          display: none;
        }
      }
    }
  }
}
body.opened {
  overflow: hidden;
  @include mq(sph) {
    .header-inner {
      background: $main-color;
    }
  }
  .header {
    #logo {
      img {
        @include mq(sph) {
          display: none;
          &.sp-logo {
            display: block;
          }
        }
      }
    }
  }
}
