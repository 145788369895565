.research {
  &.p-research {
    .box-item {
      + .box-item {
        margin-top: 20px;
      }
    }
    .box-txt {
      font-size: 1.8rem;
      @include mq {
        font-size: 1.4rem;
      }
    }
    .link-txt {
      margin-top: 10px;
    }
    .txt-18 {
      font-size: 1.8rem;
      @include mq {
        font-size: 1.4rem;
      }
    }
    .research-block {
      padding: 50px 0 0;
      @include mq {
        padding: 30px 0 0;
      }
      .ttl03 {
        @include mq(pc) {
          display: inline-block;
        }
      }
      .research-img {
        @include mq(pc) {
          float: left;
          margin: 0 40px 30px 0;
          max-width: 410px;
        }
        @include mq {
          margin: 0 0 15px;
          img {
            width: auto;
          }
        }
      }
      .box-txt {
        margin-bottom: 30px;
        @include mq {
          margin-bottom: 30px;
        }
      }
      .slider-box {
        overflow: hidden;
        .box-img {
          a {
            display: block;
            position: relative;
            overflow: hidden;
            .icon {
              background: rgba($color: $gray01, $alpha: 0.66);
              border-radius: 50%;
              -webkit-border-radius: 50%;
              height: 26px;
              width: 26px;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              position: absolute;
              right: 10px;
              bottom: 10px;
              z-index: 5;
              img {
                width: 11px;
              }
            }
            .img-hover {
              @include transition;
            }
            &:hover {
              .img-hover {
                transform: scale(1.1);
              }
            }
          }
        }
        .txt {
          font-size: 1.4rem;
          padding-top: 10px;
        }
        .swiper-slide {
          padding-right: 7px;
          max-width: 240px;
          box-sizing: content-box;
          @include mq {
            padding-right: 5px;
            max-width: 264px;
          }
        }
        
      }
      .research-box {
        margin-bottom: 50px;
        @include mq(pc) {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          > div {
            width: 48%;
          }
        }
        @include mq {
          margin-bottom: 30px;
          div + div {
            margin-top: 30px;
          }
        }
        .link {
          display: inline-block;
          padding: 10px 0 0;
          @include mq {
            padding-top: 5px;
          }
        }
      }
      dl {
        font-size: 1.8rem;
        @include mq {
          font-size: 1.4rem;
        }
        dt {
          font-weight: bold;
          &:nth-child(n+2) {
            margin-top: 20px;
            @include mq {
              margin-top: 15px;
            }
          }
        }
        dd {
          .list-txt {
            li {
              border-bottom: 1px solid $gray01;
              padding: 10px 0;
              @include mq {
                padding: 8px 0;
              }
            }
          }
        }
      }
      .list-img {
        @include mq(pc) {
          display: flex;
          flex-wrap: wrap;
          margin: -20px -1% 0;
          padding-top: 30px;
           li {
            width: 33.33%;
            padding: 20px 1% 0;
          }
        }
        @include mq {
          margin-top: 30px;
        }
        li {
          font-size: 1.4rem;
          @include mq {
            + li {
              margin-top: 20px;
              @include mq {
                margin-top: 15px;
              }
            }
          }
          .img,
          .video-box {
            position: relative;
            overflow: hidden;
            margin-bottom: 10px;
            padding-bottom: 67.25%;
            width: 100%;
            @include mq {
              margin-bottom: 8px;
            }
            iframe,
            img {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            }
            @include mq {
              height: 51.5vw;
            }
          }
        }
      }
      .ma-t50 {
        margin-top: 50px;
        @include mq {
          margin-top: 30px;
        }
      }
    }
    .research-list {
      margin-top: 50px;
      @include mq(pc) {
        li {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: space-between;
          .img {
            width: 18%;
          }
          .txt {
            width: 80%;
          }
        }
      }
      @include mq {
        margin-top: 30px;
      }
      li {
        border-bottom: 1px solid $gray01;
        padding-bottom: 15px;
        + li {
          margin-top: 15px;
        }
        @include mq {
          .img {
            margin-bottom: 15px;
          }
        }
      }
    }
    .modal {
      .img {
        text-align: center;
      }
      .popup-txt {
        margin-top: 20px;
        text-align: center;
        span {
          display: inline-block;
          background: $color-bg01;
          border-radius: 24px;
          -webkit-border-radius: 24px;
          color: $white;
          font-size: 1.8rem;
          padding: 10px 20px;
          text-align: center;
          @include mq {
            font-size: 1.4rem;
          }
        }        
      }
    }
  }
}
