.create {
  &.p-create {
    .lead-txt {
      padding-bottom: 60px;
    }
    .list {
      display: flex;
      flex-wrap: wrap;
      margin: -10px -1% 50px;
      @include mq {
        margin: -10px 0 50px;
      }
      li {
        padding: 10px 1% 0;
        margin: 0;
        width: 20%;
        @include mq {
          width: 100%;
          padding: 10px 0 0;
        }
        a {
          width: 100%;
        }
      }
    }
    .box-item {
      @include mq(pc) {
        clear: both;
        min-height: 380px;
      }
      + .box-item {
        margin-top: 20px;
      }
      .img {
        position: relative;
        overflow: hidden;
        &.pc {
          margin: 0 0 30px 30px;
          float: right;
          max-width: 221px;
        }
        &.sp {
          margin: 30px auto 0;
          max-width: 145px;
        }
        a {
          display: block;
          img {
            @include transition;
          }
          &:hover {
            img {
              transform: scale(1.1);
            }
          }
        }
      }
    }
    .box-txt {
      font-size: 1.8rem;
      @include mq {
        font-size: 1.4rem;
      }
    }
    .link-txt {
      margin-top: 10px;
    }
  }
  .view-events {
    margin-bottom: 50px;
    position: relative;
    .view-events-ttl {
      color: $color01;
      cursor: pointer;
      font-size: 2rem;
      font-weight: bold;
      @include mq {
        font-size: 1.6rem;
      }
      span {
        display: inline-block;
        padding-right: 20px;
        position: relative;
        &::after {
          content: "";
          border: solid $color01;
          border-width: 0 2px 2px 0;
          display: inline-block;
          margin-top: -2px;
          padding: 3px;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%) rotate(45deg);
          @include transition;
        }
      }
      &.active {
        span {
          &::after {
            margin-top: 2px;
            transform: translateY(-50%) rotate(-135deg);
          }
        }
      }
    }
    .events-show {
      border: 1px solid $gray01;
      border-radius: 5px;
      -webkit-border-radius: 5px;
      margin-top: 10px;
      padding: 10px 20px 30px;
      height: 300px;
      max-width: 190px;
      overflow: auto;
      display: none;
      @include mq(pc) {
        &::-webkit-scrollbar {
          width: 5px;
        }
        /* Track */
        &::-webkit-scrollbar-track {
          background: #f1f1f1;
        }
  
        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #888;
        }
  
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          // background: #555;
        }
      }
      ul {
        li {
          border-bottom: 1px solid $gray01;
          a {
            display: block;
            padding: 15px 20px 15px 0;
            position: relative;
            .arrow {
              background: transparent;
              position: absolute;
              height: 20px;
              width: 20px;
              right: 0;
              top: 50%;
              transform: translateY(-50%);
              overflow: hidden;
              &::before,
              &::after {
                content: "";
                background: url(/img/common/arrow_02.svg) no-repeat left top;
                background-size: 100% 100%;
                height: 12px;
                width: 16px;
                position: absolute;
                margin-top: -5px;
                left: 0;
                top: 50%;
                transform: rotate(90deg) translateY(-50%);
              }
              &::after {
                left: -20px;
                transform: translate(-100%,0) rotate(90deg) translateY(-50%);
                @include mq {
                  display: none;
                }
              }
            }
            &:hover {
              color: $color01;
              .arrow {
                &::before {
                  @include mq(pc) {
                    animation: nav-arrow01 0.5s alternate;
                  }
                }
                &::after {
                  @include mq(pc) {
                    animation: nav-arrow02 0.5s alternate;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
