$breakpoints: (
  "pc": "screen and (min-width: 769px)",
  "mpc": "screen and (max-width: 1500px) and (min-width: 769px)",
  "lpc": "screen and (max-width: 1240px) and (min-width: 769px)",
  "spc": "screen and (max-width: 1100px) and (min-width: 769px)",
  "sph": "screen and (max-width: 1000px)",
  "lsp": "screen and (max-width: 768px)",
  "msp": "screen and (max-width: 450px)",
  "ssp": "screen and (max-width: 320px)"
) !default;

@mixin mq($breakpoint: lsp) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}

$text-color: #333;
$color-bg01: #312F2F;
$color-bg02: #E7EEF3;
$color-bg03: #F6F6F7;
$gray01: #DBDBDB;
$gray02: #444;
$gray03: #E4E4E4;

$main-color: #333;
$white: #fff;
$black: #000;
$color01: #0081CC;
$color02:#A5A5A5;
$color03:#E7EEF3;
$color04:#DB0000;
$color05:#9A9A9A;
$color06:#D9D9D9;
$color07:#FF0000;
$color-yt: #cd201f;
$color-fb: #3b5998;
$color-tw: #55acee;
$color-ln: #00b900;

$main-width: 1200px;
$pc-header-height: 100px;
$sp-header-height: 60px;

$font-sans_serif: 游ゴシック, "Yu Gothic", YuGothic, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo, "ＭＳ Ｐゴシック", sans-serif;
$font-zenkaku:'Zen Kaku Gothic New', sans-serif;
$font-roboto: 'Roboto', sans-serif;
$font-yu-mincho: 游明朝,"Yu Mincho", "YuMincho","Hiragino Mincho ProN",HGS明朝E,メイリオ,Meiryo,serif;


@mixin inner {
  max-width: $main-width + 40;
  margin: 0 auto;
  padding: 0 20px;
  width: 100%;
  @include mq {
    padding: 0 20px;
    width: 100%;
  }
}

@mixin lh($fontSize, $height) {
  line-height: $height / $fontSize;
}
@mixin ls($tracking) {
  letter-spacing: #{$tracking / 1000}em;
}

@mixin transition($prop: all, $dur: 0.5, $func: ease, $delay: 0) {
  transition: $prop #{$dur}s $func #{$delay}s;
}

@mixin link_color($color) {
  &,
  &:visited,
  &:hover {
    color: $color;
  }
}

@mixin arrow($width, $bold, $color, $deg) {
  border-style: solid;
  border-width: 0 #{$bold}px #{$bold}px 0;
  content: "";
  display: inline-block;
  border-color: $color;
  @if ($deg == "left") {
    transform: rotate(135deg);
  } @else if ($deg == "top") {
    transform: rotate(225deg);
  } @else if ($deg == "right") {
    transform: rotate(-45deg);
  } @else if ($deg == "bottom") {
    transform: rotate(45deg);
  }
  height: #{$width}px;
  width: #{$width}px;
}

@mixin object-fit($option) {
  width: 100%;
  height: 100%;
  object-fit: $option;
}
