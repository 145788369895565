.about {
  .block {
    + .block {
      padding-top: 100px;
      @include mq {
        padding-top: 50px;
      }
    }
    .list {
      display: flex;
      flex-wrap: wrap;
      margin-top: -15px;
      @include mq {
        justify-content: space-between;
      }
      li {
        padding: 15px 0.2% 0;
        width: 33.33%;
        @include mq {
          padding: 15px 0 0;
          width: 49%;
        }
        a {
          .img {
            display: block;
            position: relative;
            overflow: hidden;
            img {
              @include transition;
            }
          }
          .txt {
            display: block;
            margin-top: 10px;
            @include mq(pc) {
              font-size: 1.8rem;
            }
          }
          &:hover {
            color: $color01;
            .img img {
              transform: scale(1.1);
            }
          }
        }
      }
    }
  }
  &.p-organization {
    .news-list {
      li {
        a {
          .txt {
            text-overflow: ellipsis;
            display: -webkit-box;
            line-clamp: 1;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            word-break: break-word;
          }
        }
      }
    }
    .img-box {
      margin: 0 auto;
      max-width: 820px;
    }
  }
  &.p-message {
    .message-box {
      @include mq(pc) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .box-img {
          width: 35%;
        }
        .box-txt {
          width: 60%;
        }
      }
      .box-txt {
        @include mq {
          margin-top: 60px;
        }
        p {
          font-size: 1.8rem;
          line-height: 1.8;
          @include mq {
            font-size: 1.6rem;
          }
          + p {
            padding-top: 40px;
          }
        }
        .president-txt {
          font-size: 1.6rem;
          text-align: right;
          img {
            margin-top: 10px;
            max-width: 250px;
          }
          @include mq {
            font-size: 1.4rem;
            img {
              max-width: 200px;
            }
          }
        }
      }
    }
  }
  &.p-history {
    .history-list {
      li {
        border-bottom: 1px solid $gray01;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-bottom: 50px;
        @include mq {
          padding-bottom: 30px;
        }
        + li {
          margin-top: 50px;
          @include mq {
            margin-top: 30px;
          }
        }
        .ttl {
          color: $color05;
          font-size: 2.4rem;
          width: 240px;
          text-align: right;
          @include mq {
            font-size: 1.6rem;
            text-align: left;
            margin-bottom: 20px;
            width: 100%;
          }
          .year-txt {
            display: block;
            font-size: 8rem;
            font-weight: 200;
            color: $color01;
            line-height: 80px;
            margin-bottom: 10px;
            @include mq {
              font-size: 4.8rem;
              line-height: 50px;
              margin-bottom: 5px;
            }
          }
        }
        .box-txt {
          font-size: 1.8rem;
          width: calc(100% - 300px);
          @include mq {
            font-size: 1.6rem;
            width: 100%;
          }
        }
        dl {
          dt {
            font-size: 2.2rem;
            margin-bottom: 10px;
            @include mq {
              font-size: 2rem;
            }
            &:nth-child(n+2) {
              margin-top: 30px;
              @include mq {
                margin-top: 20px;
              }
            }
          }
        }
      }
    }
  }
  &.p-business-lan {
    .business-list {
      li {
        border-bottom: 1px solid $gray01;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-bottom: 30px;
        @include mq {
          padding-bottom: 30px;
        }
        + li {
          margin-top: 30px;
          @include mq {
            margin-top: 20px;
          }
        }
        .ttl {
          color: $color01;
          font-size: 5rem;
          font-weight: 200;
          line-height: 1.2;
          width: 105px;
          @include mq {
            font-size: 4.8rem;            
            margin-bottom: 10px;
            width: 100%;
          }
        }
        .box-txt {
          font-size: 1.8rem;
          width: calc(100% - 105px);
          @include mq {
            font-size: 1.4rem;
            width: 100%;
          }
        }
      }
    }
  }
  &.p-access {
    .block {
      font-size: 2rem;
      @include mq {
        font-size: 1.6rem;
      }
      .list-contact {
        li {
          border-bottom: 1px solid $gray01;
          padding: 28px 0;
          @include mq {
            padding: 20px 0;
          }
          &:nth-child(1) {
            padding-top: 0;
          }
        }
      }
      .box-map {
        height: 750px;
        width: 100%;
        position: relative;
        overflow: hidden;
        margin-top: 30px;
        iframe {
          height: 100%;
          width: 100%;
        }
        @include mq {
          height: 350px;
        }
      }
    }
    .contact-box {
      height: 400px;
      position: relative;
      overflow: hidden;
      @include transition;
      .bg {
        object-fit: cover;
        height: 100%;
        width: 100%;
        position: absolute;
        z-index: 0;
        @include transition;
      }
      @include mq {
        height: 225px;
      }
      &::before {
        content: "";
        background: rgba($color: #05253C, $alpha: 0.6);
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
      }
      .inner {
        position: relative;
      }
      a {
        display: flex;
        align-items: center;
        height: 100%;
        width: 100%;
        position: relative;
        z-index: 5;
      }
      .ttl01 {
        color: $white;
        margin-bottom: 0;
        .en-ttl {
          color: $white;
          &::before {
            background: $white;
          }
        }
      }
      .arrow {
        background: transparent;
        border: 1px solid $white;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        height: 80px;
        width: 80px;
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
        overflow: hidden;
        @include mq {
          right: 20px;
          height: 40px;
          width: 40px;
        }
        &::before,
        &::after {
          content: "";
          background-color: $white;
          -webkit-mask-image: url(../../img/common/arrow_01.svg);
          mask-image: url(../../img/common/arrow_01.svg);
          -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
          -webkit-mask-size: 100% 100%;
          mask-size: 100% 100%;
          height: 15px;
          width: 20px;
          position: absolute;
          left: 30px;
          top: 50%;
          transform: translateY(-50%);            
          @include mq {
            left: 12px;
            height: 12px;
            width: 16px;
          }
        }
        &::after {
          left: -30px;
          transform: translate(-100%, 0) translateY(-50%);
          @include mq {
            left: -12px;
          }
        }
      }
      &:hover {
        .bg {
          transform: scale(1.1);
        }
        .arrow {
          &::before {
            animation: ant01 0.5s alternate;
          }
          &::after {
            animation: ant02 0.5s alternate;
          }
        }
      }
    }
  }
}
