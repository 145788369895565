.p-sitemap {
  .sitemap-block {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: -30px;
    @include mq {
      margin: 0;
    }
    .col {
      width: 30%;
      @include mq {
        width: 100%;
      }
    }
    .list {
      a {
        &:hover {
          color: $color01;
        }
      }
      > ul {
        > li {
          font-size: 2.2rem;
          @include mq {
            font-size: 1.6rem;
          }
          > a {
            border-bottom: 1px solid $gray01;
            display: block;
            padding: 15px 0;
            @include mq {
              padding: 15px 10px;
              &.pc {
                display: none;
              }
            }
          }
          &.home-link {
            @include mq(pc) {
              margin-bottom: 30px;
            }
          }
          @include mq {
            span {
              border-bottom: 1px solid $gray01;
              font-size: 1.6rem;
              display: block;
              padding: 15px 10px;
              width: 100%;
              cursor: pointer;
              position: relative;
              transition: all 0.5s ease 0s;
              &::before,
              &::after {
                content: "";
                background: $color01;
                height: 2px;
                width: 12px;
                position: absolute;
                top: 50%;
                right: 20px;
                transform: translateY(-50%);
                transition: all 0.5s ease 0s;
              }
              &::after {
                transform: translateY(-50%) rotate(90deg);
              }
            }
          }
          ul {
            margin-bottom: 30px;
            @include mq {
              display: none;
              padding: 0 15px;
              margin: 10px 0;
            }
            li {
              font-size: 1.6rem;
              margin-top: 15px;
              @include mq {
                margin: 0;
                a {
                  display: block;
                  padding: 10px 0;
                }
              }
            }
          }
          &.active {
            @include mq {
              span {
                color: $color01;
                &::after {
                  opacity: 0;
                }
              }
            }
          }
        }
      }
    }
    .box {
      margin-top: 30px;
      @include mq {
        display: none;
        margin: 0;
      }
    }
  }
  .sns-list {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin: 50px 0 0;
    li {
      & + li {
        margin-left: 20px;
        @include mq(sph) {
          margin-left: 20px;
        }
      }
      a {
        display: inline-block;
        color: $white;
        svg path {
          fill: $main-color;
        }
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
}
