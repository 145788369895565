.member {
  .news-block .news-list,
  .news-block .event-list {
    margin-bottom: 0;
  }
  #member-disaster {
    .block {
      + .block {
        margin-top: 100px;
        @include mq {
          margin-top: 50px;
        }
      }
    }
  }
  &.p-member {
    .news-list {
      padding-right: 10px;
      position: relative;
      overflow: auto;
      height: 426px;
      @include mq {
        height: 350px;
      }
      &::-webkit-scrollbar {
        border-radius: 10px;
        width: 5px;
      }
      &::-webkit-scrollbar-track {
        background: $gray01;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb {
        background: $gray02;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: $gray02;
      }
    }
    .reserveinfo_bnr {
      margin-bottom: 50px;
      @include mq {
        margin-bottom: 30px;
      }
    }
  }
  // &.p-member .p-nav .list .active-home a,
  // &.p-disaster .p-nav .list .active-disaster a,
  // &.p-certificate .p-nav .list .active-certificate a,
  // &.p-reservation .p-nav .list .active-reservation a {
  //   background: $color01;
  //   color: $white;
  //   font-weight: bold;
  // }
  &.p-certificate {
    .certificate-thanks {
      .thanks-txt {
        padding-bottom: 40px;
        @include mq {
          padding-bottom: 30px;
        }
      }
      .link-txt {
        margin-bottom: 30px;
        @include mq {
          margin-bottom: 20px;
        }
      }
      .btn {
        margin-top: 100px;
        @include mq {
          margin-top: 60px;
        }
      }
    }
  }
  &.p-movie {
    .movie-ttl {
      border-bottom: 1px solid $gray01;
      padding-bottom: 60px;
      margin-bottom: 100px;
      h2 {
        font-size: 4rem;
        font-weight: normal;
      }
      @include mq {
        padding-bottom: 30px;
        margin-bottom: 30px;
        h2 {
          font-size: 2.2rem;
        }
      }
    }
    .movie-box {
      + .movie-box {
        margin-top: 50px;
      }
      dl {
        dt {
          margin-top: 40px;
        }
      }
    }
    .video-box {
      margin-bottom: 50px;
      position: relative;
      overflow: hidden;
      padding-bottom: 56.25%;
      width: 100%;
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      @include mq {
        margin-bottom: 30px;
      }
    }
  }
  &.p-request {
    .link-txt._back {
      margin-bottom: 50px;
      a {
        padding: 7px 0 8px 50px;
        &::before {
          right: auto;
          left: 0;
        }
      }
    }
  }
  &.p-thesis {
    .block {
      + .block {
        margin-top: 50px;
      }
      .ma-b30 {
        margin-bottom: 30px;
      }
      .ma-t30 {
        margin-top: 30px;
      }
      .ma-b20 {
        margin-bottom: 20px;
      }
      h4 {
        padding-bottom: 5px;
      }
      .small-txt {
        font-size: 1.4rem;
        margin: 20px 0;
        @include mq {
          font-size: 1.2rem;
        }
      }
      .box-tbl01 {
        border: 1px solid $gray01;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        position: relative;
        overflow: hidden;
        margin-bottom: 30px;
        @include mq {
          margin-bottom: 20px;
        }
        table {          
          width: 100%;
          th {
            border-bottom: 1px solid $gray01;
            border-right: 1px solid $gray01;
            font-weight: normal;
            padding: 10px 15px;
            width: 150px;
            text-align: left;
            @include mq {
              padding: 10px;
              width: 100px;
            }
          }
          td {
            border-bottom: 1px solid $gray01;
            padding: 10px 15px;
            @include mq {
              padding: 10px;
            }
          }
          tr {
            &:last-child {
              th,td {
                border-bottom: none;
              }
            }
          }
        }
      }
      .scroll-box {
        @include mq {
          position: relative;
          overflow-x: auto;
        }
        &.scroll-hint.is-left-scrollable,
        &.scroll-hint.is-right-scrollable {
          background: none;
        }
      }
      .box-tbl02 {
        border: 1px solid $gray01;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        position: relative;
        overflow: hidden;
        @include mq {
          width: 1000px;
          &._wsp {
            width: 500px;
          }
        }
        table {          
          width: 100%;
          th {
            background: $color-bg03;
            border-bottom: 1px solid $gray01;
            border-right: 1px solid $gray01;
            font-weight: normal;
            padding: 10px 15px;
            text-align: left;
            @include mq {
              padding: 10px;
            }
            &.last {
              border-right: none;
            }
          }
          td {
            border-bottom: 1px solid $gray01;
            border-right: 1px solid $gray01;
            padding: 10px 15px;
            @include mq {
              padding: 10px;
            }
            .link-txt {
              a {
                font-size: 1.6rem;
                padding-right: 40px;
                &::before {
                  height: 30px;
                  width: 30px;
                }
                .arrow {
                  height: 30px;
                  width: 30px;
                  &::before,
                  &::after {
                    height: 8px;
                    width: 10px;
                  }
                }
                &:hover {
                  .arrow {
                    &::after {
                      margin-left: -12px;
                    }
                  }
                }
              }
              &.pdf-icon {
                a {
                  .arrow {
                    &::before,
                    &::after {
                      height: 15px!important;
                      width: 15px!important;
                    }
                    &::before {
                      margin-left: -4px;
                    }
                  }
                  &:hover {
                    .arrow {
                      &::after {
                        margin-left: -6px;
                      }
                    }
                  }
                }
              }
            }
            &.last {
              border-right: none;
            }
            a.link {
              img {
                margin-left: 10px;
                vertical-align: middle;
                width: 15px;
              }
            }
          }
          tr {
            &:last-child {
              th,td {
                border-bottom: none;
              }
            }
          }
        }
      }
      .txt-18 {
        font-size: 1.8rem;
        @include mq {
          font-size: 1.4rem;
        }
      }
      .list {
        margin-bottom: 40px;
        @include mq(pc) {
          display: flex;
          flex-wrap: wrap;
          li {
            + li {
              margin-left: 20px;
            }
          }
        }
        li {
          @include mq {
            + li {
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
  .view-events {
    margin-bottom: 50px;
    position: relative;
    .view-events-ttl {
      color: $color01;
      cursor: pointer;
      font-size: 2rem;
      font-weight: bold;
      @include mq {
        font-size: 1.6rem;
      }
      span {
        display: inline-block;
        padding-right: 20px;
        position: relative;
        &::after {
          content: "";
          border: solid $color01;
          border-width: 0 2px 2px 0;
          display: inline-block;
          margin-top: -2px;
          padding: 3px;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%) rotate(45deg);
          @include transition;
        }
      }
      &.active {
        span {
          &::after {
            margin-top: 2px;
            transform: translateY(-50%) rotate(-135deg);
          }
        }
      }
    }
    .events-show {
      border: 1px solid $gray01;
      border-radius: 5px;
      -webkit-border-radius: 5px;
      margin-top: 10px;
      padding: 10px 20px 30px;
      height: 300px;
      max-width: 250px;
      overflow: auto;
      display: none;
      @include mq(pc) {
        &::-webkit-scrollbar {
          width: 5px;
        }
        /* Track */
        &::-webkit-scrollbar-track {
          background: #f1f1f1;
        }
  
        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #888;
        }
  
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          // background: #555;
        }
      }      
      ul {
        li {
          border-bottom: 1px solid $gray01;
          a {
            display: block;
            padding: 15px 20px 15px 0;
            position: relative;
            .arrow {
              background: transparent;
              position: absolute;
              height: 20px;
              width: 20px;
              right: 0;
              top: 50%;
              transform: translateY(-50%);
              overflow: hidden;
              &::before,
              &::after {
                content: "";
                background: url(/img/common/arrow_02.svg) no-repeat left top;
                background-size: 100% 100%;
                height: 12px;
                width: 16px;
                position: absolute;
                margin-top: -5px;
                left: 0;
                top: 50%;
                transform: rotate(90deg) translateY(-50%);
              }
              &::after {
                left: -20px;
                transform: translate(-100%,0) rotate(90deg) translateY(-50%);
                @include mq {
                  display: none;
                }
              }
            }
            &:hover {
              color: $color01;
              .arrow {
                &::before {
                  @include mq(pc) {
                    animation: nav-arrow01 0.5s alternate;
                  }
                }
                &::after {
                  @include mq(pc) {
                    animation: nav-arrow02 0.5s alternate;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
