.teigen {
  &.p-teigen {
    .box-item {
      margin-bottom: 30px;
      .link-list {
        li {
          padding-left: 65px;
          position: relative;
          @include mq {
            padding-left: 0;
            padding-top: 70px;
          }
          a {
            height: auto;
          }
          .num {
            color: $color01;
            font-size: 5rem;
            line-height: 1.2;
            font-weight: 200;
            position: absolute;
            left: 0;
            top: 12px;
            text-decoration: none;
            @include mq {
              top: 10px;
              display: block;
              font-size: 4.8rem;
            }
          }
          .link-txt {
            display: block;
            margin-top: -20px;
            a {
              display: inline-block;
              width: auto;
            }
          }
        }
      }
    }
    .video-box {
      position: relative;
      overflow: hidden;
      padding-bottom: 56.25%;
      width: 100%;
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      + .video-box {
        margin-top: 40px;
        @include mq {
          margin-top: 20px;
        }
      }
    }
  }
}
