.login {
  .page {
    .inner {
      max-width: 920px;
    }
    .form-block {
      .form-list {
        dt {
          font-size: 1.4rem;
          font-weight: normal;
          @include mq {
            font-size: 1.2rem;
          }
          &:nth-child(n+2) {
            margin-top: 30px;
            @include mq {
              margin-top: 20px;
            }
          }
        }
      }
      .error-txt {
        padding-top: 10px;
        @include mq {
          font-size: 1.2rem;
        }
      }
      .link {
        margin-top: 20px;
        text-align: center;
        a {
          color: $color05;
          font-weight: bold;
          &:hover {
            opacity: 0.7;
            text-decoration: underline;
          }
        }
      }
      .thanks-txt {
        padding-bottom: 10px;
      }
    }
  }
}
