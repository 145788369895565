.wrapper-print {
  padding: 50px 0;
  @include mq {
    padding: 50px 0;
  }
}
.p-print {
  .inner {
    max-width: 1040px;
  }
  .print-block {
    border: 3px solid $gray01;
    font-family: $font-yu-mincho;
    font-weight: bold;
    padding: 50px;
    @include mq {
      padding: 80px 20px 50px;
    }
    .ttl {
      font-size: 4.8rem;
      margin-bottom: 30px;
      text-align: center;
      @include mq {
        font-size: 3rem;
        margin-bottom: 20px;
      }
    }
    &.zaikai {      
      .box-date {
        margin-bottom: 30px;
        text-align: right;
      }
      .box-address {
        align-items: center;
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
        margin: 0 0 0 auto;
        width: 400px;
        @include mq {
          width: 100%;
        }
        > div {
          position: relative;
          z-index: 5;
          width: 278px;
          @include mq {
            font-size: 1.2rem;
            width: 208px;
          }
        }
        .img {
          margin-left: -30px;
          width: 150px;
          @include mq {
            margin-left: -22px;
            width: 100px;
          }
        }
      }
      .box-01 {
        font-size: 2rem;
        margin: 50px 0;
        @include mq {
          margin: 30px 0;
        }
        p + p {
          margin-top: 50px;
          @include mq {
            margin-top: 25px;
          }
        }
        .txt {
          margin-top: 50px;
          text-align: center;
          @include mq {
            margin-top: 30px;
          }
        }
      }
      .txt-r {
        margin-top: 80px;
        text-align: right;
        @include mq {
          margin-top: 50px;
        }
      }
    }
    &.cdp {
      table {
        width: 100%;
        th {
          border-bottom: 1px solid $gray01;
          padding: 6px 0;
          width: 190px;
          text-align: left;
          @include mq {
            padding: 6px 0;
            width: 130px;
          }
        }
        td {
          border-bottom: 1px solid $gray01;
          font-size: 1.8rem;
          padding: 6px 0;
          @include mq {
            font-size: 1.5rem;
            padding: 6px 0;
          }
        }
        .txt {
          padding-top: 10px;
        }
        .img {
          margin: 0 auto;
          max-width: 200px;
          @include mq {
            max-width: 180px;
          }
        }
      }
    }
    .print-btn {
      position: fixed;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

@media print {
  .noPrint{
    display:none;
  }
}