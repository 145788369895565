.p-news {
  &.p-zoom {
    .posts-ttl {
      .posts-date {
        display: flex;
        align-items: center;
        .en {
          font-size: 2rem;
          font-weight: bold;
          @include mq {
            font-size: 1.6rem;
          }
        }
        .category-txt {
          background: $color-bg02;
          display: inline-block;
          font-size: 1.4rem;
          margin-left: 10px;
          padding: 2px 10px 2px 10px;
          @include mq {
            font-size: 1.2rem;
          }
        }
      }
    }
    .cmn-sec {
      padding-top: 60px;
      @include mq {
        padding-top: 30px;
      }
      .box-txt {
        font-size: 1.8rem;
        @include mq {
          font-size: 1.4rem;
        }
        a {
          color: $color01;
          font-weight: bold;
          &:hover {
            opacity: 0.7;
            text-decoration: underline;
          }
        }
        .link-txt {
          margin-top: 40px;
          @include mq {
            margin-top: 20px;
          }
        }
      }
    }
  }
}
