.footer {
  background: $color-bg01;
  color: $white;
  padding: 100px 0 50px;
  position: relative;
  @include mq(sph) {
    padding: 50px 0 30px;
    .pc {
      display: none;
    }
    .sp {
      display: block;
    }
  }
  a {
    color: $white;
  }
  &-inner {
    margin: 0 auto;
    max-width: 1540px;
    padding: 0 20px;
    width: 100%;
    @include mq(sph) {
      max-width: 100%;
    }
  }
  #page-top {
    position: absolute;
    top: -40px;
    right: 50px;
    z-index: 99;
    @include mq(sph) {
      top: -30px;
      right: 20px;
    }
    a {
      border: 1px solid $gray01;
      background: $white;
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      height: 80px;
      width: 80px;
      justify-content: center;
      @include mq(sph) {
        height: 60px;
        width: 60px;
        svg {
          width: 12px;
        }
      }
      .arrow {
        background: transparent;
        position: absolute;
        border-radius: 50%;
        height: 80px;
        width: 80px;
        left: 0;
        top: 0;
        overflow: hidden;
        @include mq(sph) {
          height: 60px;
          width: 60px;
        }
        &::before,
        &::after {
          content: "";
          background-color: $color01;
          -webkit-mask-image: url(../../img/common/arrow_02.svg);
          mask-image: url(../../img/common/arrow_02.svg);
          -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
          -webkit-mask-size: 100% 100%;
          mask-size: 100% 100%;
          height: 20px;
          width: 16px;
          position: absolute;
          left: 50%;
          top: 30px;
          transform: translateX(-50%);  
          @include mq(sph) {
            height: 15px;
            width: 12px;
            top: 25px;
          }        
        }
        &::after {
          top: 100px;
          transform: translate(-50%,100%);
          @include mq(sph) {
            top: 100px;
          }
        }
      }
      &:hover {
        background: $color01;
        border-color: $color01;
        .arrow {
          &::before,
          &::after {
            background-color: $white;
          }
          &::before {
            animation: arrow01 0.5s alternate;
          }
          &::after {
            animation: arrow02 0.5s alternate;
          }
        }
      }
    }
    @keyframes arrow01 {
      0% {
        top: 25px;
      }
      100% {
        top: -30px;
      }
    }
    @keyframes arrow02 {
      0% {
        top: 100px;
      }
      100% {
        top: 10px;
      }
    }
  }
  .f-logo {
    width: 26.7%;
    @include mq(sph) {
      order: 2;
      margin: 100px 0 20px;
      width: 60%;
      max-width: 400px;
    }
  }
  &-top {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 100px;
    position: relative;
    @include mq(sph) {
      padding-bottom: 0;
    }
  }
  &-link {    
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    width: 70%;
    @include mq(sph) {
      order: 1;
      width: 100%;
    }
    .col {
      width: 145px;
      &:nth-child(2) {
        margin: 0 50px;
        width: 250px;
      }
      @include mq(sph) {
        width: 100%;
        &:nth-child(2) {
          margin: 0;
          width: 100%;
        }
      }
      .f-nav01 {
        margin-top: 0;
        > ul {
          > li {
            margin-bottom: 15px;
            @include mq(sph) {
              margin-bottom: 0;
            }
            > a {
              &:hover {
                color: $color01;
              }
              @include mq(sph) {
                border-bottom: 1px solid $gray02;
                display: block;
                font-size: 1.6rem;
                padding: 15px 10px;
                &.pc {
                  display: none;
                }
              }
            }
            span {
              display: none;
              @include mq(sph) {
                border-bottom: 1px solid $gray02;
                font-size: 1.6rem;
                display: block;
                padding: 15px 10px;
                width: 100%;
                cursor: pointer;
                position: relative;
                @include transition;
                &::before,
                &::after {
                  content: "";
                  background: $white;
                  height: 2px;
                  width: 12px;
                  position: absolute;
                  top: 50%;
                  right: 20px;
                  transform: translateY(-50%);
                  @include transition;
                }
                &::after {
                  transform: translateY(-50%) rotate(90deg);
                }
              }
            }
            &.active {
              @include mq(sph) {
                span {
                  color: $color01;
                  &::after {
                    opacity: 0;
                  }
                }
              }
            }        
            > ul {
              display: none;
              @include mq(sph) {
                display: none;
                padding: 10px 10px ;
              }
              li {
                a {
                  display: block;
                  padding: 10px 0;
                  font-size: 1.6rem;
                  &:hover {
                    color: $color01;
                  }
                }
              }
            }
          }
        }
              
      }
    }
  }
  
  &-bottom {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    > div {
      width: 50%;
      @include mq(sph) {
        width: 100%;
      }
    }
    .txt {
      font-size: 1.2rem;
      @include mq(sph) {
        font-size: 1.2rem;
      }
    }
  }
  .f-nav02 {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-bottom: 10px;
    @include mq(sph) {
      justify-content: flex-start;
      margin: -10px -5px 10px;
    }
    li {
      font-size: 1.2rem;
      line-height: 15px;
      @include mq(sph) {
        margin: 10px 5px 0;
      }
      + li {
        margin-left: 10px; 
        @include mq(sph) {
          margin-left: 5px;
        }     
      }
      a {
        display: block;
        &:hover {
          color: $color01;
        }
      }
    }
  }
  .sns-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 30px;
    @include mq(sph) {
      margin: 20px 0 40px;
    }
    li {
      & + li {
        margin-left: 20px;
      }
      a {
        display: inline-block;
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
  .copy-right {
    color: $color02;
    font-size: 1.2rem;
    font-family: $font-roboto;
    text-align: right;
    @include mq(sph) {
      text-align: left;
    }
  }
  &._02 {
    height: 114px;
    padding: 48px 0;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    @include mq {
      padding: 35px 0;
      height: 100px;
    }
  }
}
