// Base
@import 'base/base';
@import 'base/fontawesome';
@import 'https://use.fontawesome.com/releases/v6.5.1/css/all.css';
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@200;300;400;500;600;700;800;900&family=Zen+Kaku+Gothic+New:wght@300;400;500;700;900&family=Roboto:wght@500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

// Mixin
@import 'mixin/mixin';
@import 'mixin/z-index';
@import 'mixin/animation';

// Plubin
@import 'plugin/swiper-bundle.min';
@import 'plugin/modal';
@import 'plugin/scroll-hint';
@import 'plugin/jquery-ui';

//-----------
// header
//-----------
@import 'layout/header';

//-----------
// footer
//-----------
@import 'layout/footer';

// ------------------------------
// TOP
// ------------------------------
@import 'page/top';

// ------------------------------
// 下層 共通
// ------------------------------
@import 'page/page';
@import 'page/about';
@import 'page/event';
@import 'page/member';
@import 'page/news';
@import 'page/committee';
@import 'page/login';
@import 'page/teigen';
@import 'page/research';
@import 'page/technology';
@import 'page/create';
@import 'page/cpd_qualifications_links';
@import 'page/contest';
@import 'page/policy';
@import 'page/sitemap';
@import 'page/consultation';
@import 'page/careers';
@import 'page/print';
@import 'page/disaster';
