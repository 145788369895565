.cpd_qualifications_links {
  .block {
    + .block {
      margin-top: 100px;
      @include mq {
        margin-top: 80px;
      }
    }
  }
  .box-item {
    + .box-item {
      margin-top: 20px;
    }
  }
  &.p-cpd_qualifications_links {    
    .link-list {
      &._link02 {
        li a {
          font-size: 1.6rem;
          @include mq {
            font-size: 1.4rem;
          }
          .ttl {
            font-size: 1.8rem;
            font-weight: bold;
            display: block;
            padding-bottom: 10px;
            @include mq {
              font-size: 1.6rem;
              padding-bottom: 5px;
            }
          }
        }
      }
    }
  }
}
