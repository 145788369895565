.p-consultation {
  .txt01 {
    margin-bottom: 60px;
  }
  .box-item {
    margin-top: 30px;
    .link-list {
      margin-top: 30px;
    }
  }
  .txt-list {
    margin: 30px 0 35px;
    li {
      padding-left: 2rem;
      text-indent: -2rem;
      .dot {
        font-size: 2rem;
        text-indent: 0;
      }
    }
  }
  .txt02 {
    font-size: 1.4rem;
    font-weight: bold;
  }
  .box-txt {
    font-size: 1.4rem;
    padding-top: 20px; 
    @include mq(pc) {
      padding: 0 0 0 40px;
    }
    .link-list {
      padding: 20px 0 15px;
      li {
        @include mq(pc) {
          width: 234px;
        }
        a {
          font-size: 1.6rem;
          padding: 15px 50px 15px 0;
          &::before {
            display: none;
          }
          .arrow {
            right: -10px;
            &::before,
            &::after {
              background-color: $color01;
            }
          }
        }
      }
    }
    .small-txt {
      font-size: 1.2rem;
    }
  }
}
