.p-top {
  .top-mainv {
    max-height: 630px;
    position: relative;
    overflow: hidden;
    width: 100%;
    @include mq {
      height: 215px;
    }
    img {
      width: 100%;
    }    
    .main-txt {
      position: absolute;
      left: 10%;
      bottom: 12%;
      width: 58.5%;
      z-index: 5;
      @include mq {
        left: 15px;
        bottom: 15px;
        width: 60%;
      }
    }
    .main-slider {
      position: relative;
      overflow: hidden;
      height: 100%;
      &.show {
        animation: opacity 2s alternate;
        opacity: 1;
      }
      a {
        display: block;
      }
      .swiper {
        width: 100%;
        height: 100%;
        .swiper-slide {
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            height: 100%;
            object-fit: cover;
          }
          .swiper-txt {
            background: $white;
            font-size: 1.6rem;
            padding: 20px 15px;
            position: absolute;
            bottom: 5px;
            right: 5px;
            width: 270px;
            @include mq {
              font-size: 0.7rem;
              padding: 10px;
              width: 135px;
              bottom: 1px;
              right: 1px;
            }
          }
        }
      }
    }
  }
  @keyframes opacity { 
    0% {
      opacity: 0;
    }  
    100% {
      opacity: 1;
    }
  }
  #top-pickup {
    .pickup-block {
      position: relative;
      ul {
        display: flex;
        margin: 0 -3px;
        @include mq {
          flex-wrap: wrap;
          margin-top: -15px;
          width: 100%;
        }
        li {
          padding: 0 3px;
          width: 100%;
          @include mq {
            margin-top: 15px;
            width: 50%;
          }
          a {
            display: block;
            &:hover {
              color: $color01;
              .img img {
                transform: scale(1.1);
              }
            }
          }
          .img {
            height: 410px;
            display: block;
            position: relative;
            overflow: hidden;
            width: 100%;
            @include mq {
              height: 233px;
            }
            img {
              object-fit: cover;
              height: 100%;
              width: 100%;
              @include transition;
            }
          } 
          .txt {
            display: block;
            margin: 10px 15% 0 0;
            max-width: 220px;
          }
          &.img-b {
            .img {
              border: 1px solid $gray01;
            }
          }
        }
        @include mq(pc) {
          &.col2 {
            li {
              width: 50%;
            }
          }
          &.col3 {
            li {
              width: 33.33%;
            }
          }
          &.col4 {
            li {
              width: 25%;
            }
          }
        }
      }
    }
  }
  #top-news {
    padding-top: 0;
    .link-txt {
      text-align: right;
    }
  } 
  #top-report {
    position: relative;
    .bg {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      img {
        width: 100%;
      }
    }
    .inner {
      position: relative;
      z-index: 5;
    }
    .link-txt {
      position: absolute;
      top: 50px;
      right: 20px;
      @include mq {
        position: static;
        top: auto;
        right: auto;
        text-align: center;
        margin-top: 30px;
      }
    }
    .report-list {
      display: flex;
      flex-wrap: wrap;
      margin: -15px -0.8% 0;
      @include mq {
        justify-content: space-between;
        margin: -15px 0 0;
      }
      li {
        margin: 15px 0.8% 0;
        width: 23.4%;
        @include mq {
          margin: 15px 0 0;
          width: 48%;
        }
        a {
          display: block;
          &:hover {
            color: $color01;
            .img img {
              transform: scale(1.1);
            }
          }
        }
        .img {
          display: block;
          position: relative;
          overflow: hidden;
          height: 26vw;
          @include mq(pc) {
            max-height: 380px;
          }
          @include mq {
            height: 56vw;
          }
          img {
            object-fit: cover;
            height: 100%;
            width: 100%;
            @include transition;
          }
        }
        .txt {
          display: block;
          font-size: 1.8rem;
          margin-top: 10px;
          @include mq {
            font-size: 1.4rem;
          }
        }
      }
    }
  }
  #top-books {
    .books-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      @include mq {
        margin-top: -15px;
      }
      li {
        width: 25%;
        @include mq {
          margin-top: 15px;
          width: 50%;
        }
        a {
          display: block;
          &:hover {
            color: $color01;
            .img img {
              transform: scale(1.1);
            }
          }
        }
        span {
          padding-right: 10px;
          display: block;
        }
        .img {
          border: 1px solid $gray01;
          height: 366px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          padding: 0;
          margin-bottom: 15px;
          position: relative;
          overflow: hidden;
          @include mq(lpc) {
            height: 28vw;
          }
          @include mq {
            margin-bottom: 10px;
            height: 55vw;
          }
          img {
            @include transition;
            width: 70%;
          }
        }
        + li {
          .img {
            border-left: none;
          }
        }
        @include mq {
          &:nth-child(odd) {
            .img {
              border-left: 1px solid $gray01;
            }
          }
        }
      }
    }
  }
  #top-library {
    height: 1000px;
    position: relative;
    padding: 0 50px;
    overflow: hidden;
    @include mq(mpc) {
      padding: 0 20px;
      height: 52vw;
      .map_img {
        top: 13vw;
        width: 30%;
      }
    }
    @include mq {
      height: auto;
      padding: 90px 0 0;
    }
    .map_img {
      position: absolute;
      top: 345px;
      right: 0;
      width: 32%;
      @include mq {
        top: 50px;
        width: 77%;
      }
    }
    .inner {
      height: 100%;
      position: relative;
      z-index: 5;
    }
    .box-txt {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      .btn {
        margin-top: 0;
        text-align: left;
      }
      @include mq {
        position: static;
        top: 0;
        right: auto;
        transform: translateY(0);
        padding-bottom: 50px;
      }
      @include mq(spc) {
        .ttl01 .jp-ttl {
          font-size: 4rem;
        }
        .btn a {
          font-size: 1.4rem;
          width: 250px;
        }
      }
    }
    .carousel-field {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;
      @include mq(pc) {
        width: 1020px;
        height: 100%;
        position: absolute;
        left: 60px;
        overflow: hidden;
        top: 0;
        @include mq(mpc) {
          width: 54%;
        }
      }
      .reverse-marquee-carousel,
      .marquee-carousel {
        width: 100%;
        @include mq(pc) {
          width: 500px;
          height: 100%;
          @include mq(mpc) {
            width: 49%;
            .swiper-slide img {
              max-height: 100%;
              width: auto;
            }
          }
        }
        @include mq {
          .swiper-slide {
            // max-width: 300px!important;
          }
        }
        .swiper-wrapper {
          -webkit-transition-timing-function: linear !important;
          transition-timing-function: linear !important; 
          .swiper-slide {
            display: flex;
            justify-content: center;
            align-items: center;
            @include mq {
              padding: 0 0 0 10px;
            }
          }
        }
      }
      .marquee-carousel {
        @include mq {
          margin-bottom: 10px;
        }
      }
    }
  }
  #top-bnr {
    .bnr-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      margin: -20px -0.8% 0;
      @include mq {
        margin: 0;
      }
      li {
        margin: 20px 0.8% 0;
        width: 23.4%;
        @include mq {
          margin: 0;
          width: 100%;
          + li {
            margin-top: 10px;
          }
        }
        a {
          display: block;
          &:hover {
            opacity: 0.7;
          }
        }
      }
    }
  }
}
