.event {
  &.p-zoom {
    .posts-date {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .en {
        font-size: 2rem;
        font-weight: bold;
        @include mq {
          font-size: 1.6rem;
        }
      }
      .txt {
        font-size: 1.8rem;
        font-weight: bold;
        display: inline-block;
        margin-top: 1px;
        @include mq {
          font-size: 1.4rem;
          margin-top: -2px;
        }
      }
    }
    .btn {
      @include mq(pc) {
        margin-top: 50px;
      }
    }
    .status-box {
      display: flex;
      align-items: center;
      margin-left: 10px;
      .accepted-txt,
      .unaccept-txt {
        background: $main-color;
        border: 1px solid $main-color;
        color: $white;
        font-size: 1.4rem;
        font-weight: bold;
        padding: 5px 0 3px;
        width: 86px;
        text-align: center;
        @include mq {
          font-size: 1.1rem;
          padding: 3px 0 2px;
          width: 64px;
        }
      }
      .attention-txt {
        color: $color04;
        font-size: 1.6rem;
        padding-left: 10px;
        @include mq {
          font-size: 1.2rem;
        }
      }
      .unaccept-txt {
        background: $white;
        color: $main-color;
      }
    }
    .cmn-sec {
      padding-top: 60px;
      @include mq {
        padding-top: 30px;
      }
      .tbl-style01 {
        td {
          .link {
            display: inline-block;
            color: $color01;
            font-family: $font-roboto;
            font-size: 1.8rem;
            margin-top: 10px;
            svg {
              vertical-align: middle;
            }
            @include mq {
              font-size: 1.4rem;
            }
            &:hover {
              text-decoration: underline;
            }
          }
          .status-box02 {
            display: flex;
            flex-wrap: wrap;
            span {
              background: $white;
              border: 1px solid $text-color;
              color: $text-color;
              font-weight: 700;
              padding: 5px 14px;
              display: inline-block;
              text-align: center;
              + span {
                margin-left: 10px;
                @include mq {
                  margin: 10px 0 0;
                }
              }
            }
          }
        }
      }
    }
  }
  &.p-reservation {
    .ttl-form04 {
      margin-bottom: 100px;
      @include mq {
        margin-bottom: 80px;
      }
    }
  }
}
