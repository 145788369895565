.p-policy {
  dl {
    dt {
      &:nth-child(n+2) {
        margin-top: 60px;
        @include mq {
          margin-top: 40px;
        }
      }
    }
  }
}
