.inner {
  @include inner;
}
.wrapper {
  margin-top: $pc-header-height;
  @include mq(sph) {
    margin-top: $sp-header-height;
  }
}
.txt-c {
  text-align: center;
}
.page {
  .cmn-sec {
    padding: 100px 0;
    @include mq {
      padding: 80px 0;
    }
    &.sec-bg {
      background: $color-bg03;
    }
    &.blue-bg {
      background: $color-bg02;
    }
    &.black-bg {
      background: $main-color;
      .ttl01 {
        .jp-ttl,
        .en-ttl {
          color: $white;
        }
        .en-ttl {
          &::before {
            background: $white;
          }
        }
      }
    }
    .box-item {
      background: $color-bg03;
      padding: 40px;
      + .box-item {
        margin-top: 20px;
      }
      @include mq {
        padding: 20px;
      }
    }
    .link-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: -10px;
      li {
        border-bottom: 1px solid $gray01;
        width: 49%;
        @include mq {
          width: 100%;
        }
        a {
          display: inline-flex;
          flex-direction: column;
          justify-content: center;
          font-size: 1.8rem;
          height: 100%;
          width: 100%;
          padding: 20px 50px 20px 0;
          position: relative;
          @include mq {
            font-size: 1.6rem;
          }
          &::before {
            content: "";
            background: $color01;
            border: 1px solid $color01;
            border-radius: 50%;
            -webkit-border-radius: 50%;
            height: 40px;
            width: 40px;
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            @include transition;
          }
          .arrow {
            background: transparent;
            border-radius: 50%;
            -webkit-border-radius: 50%;
            display: block;
            height: 40px;
            width: 40px;
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            overflow: hidden;
            &::before,
            &::after {
              content: "";
              background-color: $white;
              -webkit-mask-image: url(../../img/common/arrow_01.svg);
              mask-image: url(../../img/common/arrow_01.svg);
              -webkit-mask-repeat: no-repeat;
              mask-repeat: no-repeat;
              -webkit-mask-size: 100% 100%;
              mask-size: 100% 100%;
              height: 12px;
              width: 16px;
              position: absolute;
              left: 12px;
              top: 50%;
              transform: translateY(-50%);
            }
            &::after {
              left: -10px;
              transform: translate(-200%, -50%);
            }
          }
          &:hover {
            &::before {
              background: $white;
              border: 1px solid $gray01;
            }
            .arrow {
              &::before,
              &::after {
                background-color: $color01;
              }
              &::before {
                animation: animate-arrow01 0.5s alternate;
              }
              &::after {
                animation: animate-arrow02 0.5s alternate;
              }
            }
            
          }
          &.blank-icon {
            .arrow {
              &::before,
              &::after {
                -webkit-mask-image: url(../../img/common/blank_icon.svg);
                mask-image: url(../../img/common/blank_icon.svg);
                -webkit-mask-repeat: no-repeat;
                mask-repeat: no-repeat;
                -webkit-mask-size: 100% 100%;
                mask-size: 100% 100%;
                height: 15px;
                width: 15px;
              }
              &::before {
                margin-left: 0px;
              }
              &::after {
                margin-left: -2px;
              }
            }
          }
          &.pdf-icon {
            .arrow {
              &::before,
              &::after {
                -webkit-mask-image: url(../../img/common/pdf_icon.svg)!important;
                mask-image: url(../../img/common/pdf_icon.svg)!important;
                -webkit-mask-repeat: no-repeat!important;
                mask-repeat: no-repeat!important;
                -webkit-mask-size: 100% 100%;
                mask-size: 100% 100%;
                height: 20px!important;
                width: 20px!important;
              }
              &::before {
                margin-left: -1px;
              }
              &::after {
                margin-left: 6px;
              }
            }
          }
          &.word-icon {
            .arrow {
              &::before,
              &::after {
                -webkit-mask-image: url(../../img/common/word_icon.svg)!important;
                mask-image: url(../../img/common/word_icon.svg)!important;
                -webkit-mask-repeat: no-repeat!important;
                mask-repeat: no-repeat!important;
                -webkit-mask-size: 100% 100%;
                mask-size: 100% 100%;
                height: 22px!important;
                width: 22px!important;
              }
              &::before {
                margin-left: -3px;
              }
              &::after {
                margin-left: 9px;
              }
            }
          }
        }
      }
      &._col4 {        
        @include mq(pc) {
          justify-content: flex-start;
          margin: -10px -2% 0;
        }
        li {
          margin: 0 2%;
          width: 16%;
          a {
            font-size: 1.6rem;
          }
          @include mq {
            margin: 0;
            width: 48%;
          }
        }
      }
    }
  }
  .ttl01 {
    font-weight: 500;
    margin-bottom: 60px;
    @include mq {
      margin-bottom: 40px;
    }
    .jp-ttl {
      font-size: 4.8rem;
      span {
        font-size: 2rem;
        margin-left: 10px;
      }
      @include mq {
        font-size: 3.2rem;
      }
    }
    .en-ttl {
      font-family: $font-roboto;
      font-size: 1.6rem;
      text-transform: uppercase;
      display: block;
      color: $color01;
      padding-left: 25px;
      position: relative;
      @include mq {
        font-size: 1.2rem;
        padding-left: 15px;
      }
      &::before {
        content: "";
        background: $color01;
        height: 2px;
        width: 20px;
        position: absolute;
        left: 0;
        top: 10px;
        @include mq {
          height: 1px;
          width: 10px;
          top: 8px;
        }
      }
    }
  }
  .ttl02 {
    font-size: 3.2rem;
    font-weight: 500;
    margin-bottom: 30px;
    padding-left: 20px;
    position: relative;
    @include mq {
      margin-bottom: 20px;
      padding-left: 15px;
      font-size: 2.4rem;
    }
    &::before {
      content: "";
      background: $color01;
      height: 48px;
      width: 2px;
      position: absolute;
      left: 0;
      top: 0;
      @include mq {
        height: 36px;
      }
    }
  }
  .ttl03 {
    font-size: 2.4rem;
    font-weight: 500;
    padding: 0 0 20px 20px;
    position: relative;
    @include mq {
      font-size: 2rem;
      padding: 0 0 15px 20px;
    }
    &::before {
      content: "";
      background: $color01;
      height: 2px;
      width: 10px;
      position: absolute;
      left: 0;
      top: 15px;
      @include mq {
        height: 1px;
        top: 13px;
      }
    }
  }
  .en {
    font-family: $font-roboto;
  }
  .main-ttl {
    background: url(../../img/common/ttl_bg.webp) no-repeat center bottom;
    background-size: cover;
    height: 313px;
    position: relative;
    overflow: hidden;
    @include mq {
      background: url(../../img/common/ttl_bg_sp.webp) no-repeat center bottom;
      background-size: cover;
      height: 261px;
    }
    .inner {
      display: flex;
      align-items: flex-end;
      height: 100%;
      max-width: 1500px;
      position: relative;
      z-index: 5;
      width: 100%;
    }
    h1 {
      background: $white;
      font-weight: 500;
      padding: 35px 40px;
      @include mq {
        padding: 20px 15px;
      }
      .jp-ttl {
        font-size: 6rem;
        span {
          font-size: 2.4rem;
          margin-left: 10px;
        }
        @include mq {
          font-size: 3.5rem;
          span {
            font-size: 1.8rem;
            margin: 0;
            display: block;
          }
        }
      }
      .en-ttl {
        font-family: $font-roboto;
        font-size: 1.8rem;
        color: $color01;
        text-transform: uppercase;
        display: block;
        margin-top: 10px;
        padding-left: 25px;
        position: relative;
        @include mq {
          font-size: 1.2rem;
          padding-left: 15px;
        }
        &::before {
          content: "";
          background: $color01;
          height: 2px;
          width: 20px;
          position: absolute;
          left: 0;
          top: 11px;
          @include mq {
            height: 1px;
            width: 10px;
            top: 8px;
          }
        }
      }
    }
    &._02 {
      background: $color-bg03;
      height: 223px;
      @include mq {
        height: 189px;
      }
      .inner {
        align-items: center;
      }
      h1 {
        background: none;
        padding: 0;
      }
    }
  }
  a.link {
    color: $color01;
    font-weight: bold;
    img {
      vertical-align: middle;
      margin-left: 5px;
      @include mq {
        margin-top: -2px;
        width: 15px;
      }
    }
    &:hover {
      opacity: 0.7;
      text-decoration: underline;
    }
  }
  .p-nav {
    background: $color-bg03;
    z-index: 99;
    width: 100%;
    @include transition;
    @include mq(pc) {
     &.fixed {
      position: fixed;
      left: 0;
      top: 100px;
     }
    }
    @include mq(sph) {
      position: fixed;
      left: 0;
      bottom: 0;
      top: auto!important;
      &.hide {
        opacity: 0;
        z-index: -1;
      }
    }
    .inner {
      @include mq {
        padding: 0;
      }
    }
    .list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      li {        
        width: 20%;        
        a {
          display: inline-flex;
          align-items: center;
          letter-spacing: 0;
          height: 100%;
          width: 100%;
          padding: 10px;
          text-align: center;
          justify-content: center;
          @include mq {
            font-size: 1.1rem;
            padding: 15px 0;
          }
          &:hover {
            background: $color01;
            color: $white;
            font-weight: bold;
          }
        }
        &.active{
          a {
            background: $color01;
            color: $white;
            font-weight: bold;
          }
        }
      }
      &.col-4 {
        li {
          width: 25%;
        }
      }
    }
  }
  .posts-ttl {
    border-bottom: 1px solid $color06;
    padding: 100px 0 60px;
    @include mq {
      padding: 50px 0 30px;
    }
    h2 {
      font-size: 4rem;
      font-weight: 500;
      padding-top: 20px;
      @include mq {
        font-size: 2.2rem;
      }
    }
  }
  .posts-link {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 100px;
    @include mq {
      margin-top: 80px;
    }
    a {
      &.list-btn {
        background: $main-color;
        border: 1px solid $main-color;
        border-radius: 50px;
        -webkit-border-radius: 50px;
        color: $white;
        font-size: 1.6rem;
        font-weight: bold;
        margin: 0 auto;
        padding: 15px 20px 15px 50px;
        position: relative;
        .arrow {
          background: transparent;
          border-radius: 30px;
          display: inline-block;
          height: 40px;
          width: 40px;
          left: 0;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          overflow: hidden;
          &::before,
          &::after {
            content: "";
            background-color: $white;
            -webkit-mask-image: url(../../img/common/arrow_01.svg);
            mask-image: url(../../img/common/arrow_01.svg);
            -webkit-mask-repeat: no-repeat;
            mask-repeat: no-repeat;
            -webkit-mask-size: 100% 100%;
            mask-size: 100% 100%;
            height: 10px;
            width: 13px;
            position: absolute;
            left: 20px;
            top: 50%;
            transform: translateY(-50%) rotate(180deg);
          }
          &::after {
            left: 45px;
            margin-left: -4px;
            transform: translateY(-50%) rotate(180deg);
          }
        }
        &:hover {
          background: $white;
          border: 1px solid $gray01;
          color: $main-color;
          .arrow {
            &::before,
            &::after {
              background-color: $main-color;
            }
            &::before {
              animation: btn-arrow03 0.5s alternate;
            }
            &::after {
              animation: btn-arrow04 0.5s alternate;
            }
          }
        }
      }
      &.prev,
      &.next {
        border: 1px solid $gray01;
        border-radius: 50%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 60px;
        width: 60px;
        text-align: center;
        position: relative;
        @include mq {
          height: 40px;
          width: 40px;
        }
        &:hover {
          background: $color01;
          border-color: $color01;
        }
        .arrow {
          background: transparent;
          border-radius: 50%;
          -webkit-border-radius: 50%;
          display: block;
          height: 60px;
          width: 60px;
          position: absolute;
          top: 50%;
          left: 0px;
          transform: translateY(-50%);
          overflow: hidden;
          @include mq {
            height: 40px;
            width: 40px;
          }
          &::before,
          &::after {
            content: "";
            background-color:$color01;
            -webkit-mask-image: url(../../img/common/arrow_01.svg);
            mask-image: url(../../img/common/arrow_01.svg);
            -webkit-mask-repeat: no-repeat;
            mask-repeat: no-repeat;
            -webkit-mask-size: 100% 100%;
            mask-size: 100% 100%;
            height: 12px;
            width: 16px;
            position: absolute;
            left: 20px;
            top: 50%;
            transform: translateY(-50%);
            @include mq {
              left: 12px;
            }
          }
          &::after {
            left: -20px;
            transform: translate(-200%, -50%);
          }
        }
        &.prev {
          transform: rotate(180deg);
        }
        &:hover {
          &::before {
            background: $white;
            border: 1px solid $gray01;
          }
          .arrow {
            &::before,
            &::after {
              background-color: $white;
            }
            &::before {
              animation: circle-arr1 0.5s alternate;
            }
            &::after {
              animation: circle-arr2 0.5s alternate;
            }     
          }
        }            
      }
    }
    @keyframes circle-arr1 {
      0% {
        left: 20px;
      }
      100% {
        left: 60px;
      }
    }
    @keyframes circle-arr2 {
      0% {
        left: -20px;
      }
      100% {
        left: 52px;
      }
    }
    @keyframes circle-arr3 {
      0% {
        left: 54px;
      }
      100% {
        left: -20px;
      }
    }
    @keyframes circle-arr4 {
      0% {
        left: 100px;
      }
      100% {
        left: 54px;
      }
    }
  }
  .btn-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: -20px -10px 0;
    @include mq {
      margin: -15px 0 0;
    }
    li {
      margin: 20px 10px 0;
      text-align: left;
      @include mq {
        margin: 15px 0 0;
        width: 100%;
      }
      a {
        width: auto;
        @include mq {
          width: 100%;
        }
      }
    }
  }
  .box-btn {
    @include mq(pc) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      .btn {
        order: 2;
        &._black {
          order: 1;
        }
      }
    }
    @include mq {
      padding-top: 40px;
    }
    .btn {
      margin-top: 50px;
      @include mq {
        margin-top: 20px;
      }
    }
  }
  .top-link {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 100px 50px 0;
    @include mq {
      padding: 40px 20px 0;
    }
    > div {
      width: 50%;
      @include mq {
        width: 100%;
      }
      a {
        display: block;
        height: 100%;
        width: 100%;
        position: relative;
        overflow: hidden;
        img {
          width: 100%;
          @include transition;
        }
        .ttl01 {
          color: $white;
          display: block;
          padding: 60px;
          margin: 0;
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          z-index: 5;
          @include mq(spc) {
            padding: 30px 20px;
            .jp-ttl {
              font-size: 3.6rem;
            }
            &::before {
              height: 60px;
              width: 60px;
            }
            &::after {
              right: 80px;
            }
          }
          @include mq {
            padding: 20px;
          }
          .en-ttl {
            color: $white;
            &::before {
              background-color: $white;
            }
          }
        }
        &::after {
          content: "";
          background-image: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
          height: 300px;
          width: 100%;
          position: absolute;
          left: 0;
          bottom: 0;
          z-index: 0;
          @include mq {
            height: 110px;
          }
        }
        .arrow {
          background: transparent;
          border: 1px solid $white;
          border-radius: 50%;
          -webkit-border-radius: 50%;
          height: 80px;
          width: 80px;
          position: absolute;
          top: 50%;
          right: 60px;
          transform: translateY(-50%);
          overflow: hidden;
          @include mq {
            right: 20px;
            height: 40px;
            width: 40px;
          }
          &::before,
          &::after {
            content: "";
            background-color: $white;
            -webkit-mask-image: url(../../img/common/arrow_01.svg);
            mask-image: url(../../img/common/arrow_01.svg);
            -webkit-mask-repeat: no-repeat;
            mask-repeat: no-repeat;
            -webkit-mask-size: 100% 100%;
            mask-size: 100% 100%;
            height: 15px;
            width: 20px;
            position: absolute;
            left: 30px;
            top: 50%;
            transform: translateY(-50%);            
            @include mq {
              left: 12px;
              height: 12px;
              width: 16px;
            }
          }
          &::after {
            left: -30px;
            transform: translate(-100%, 0) translateY(-50%);
            @include mq {
              left: -12px;
            }
          }
        }
        &:hover {
          img {
            transform: scale(1.1);
          }
          .arrow {
            &::before {
              animation: ant01 0.5s alternate;
            }
            &::after {
              animation: ant02 0.5s alternate;
            }
          }
        }
      }
    }
    @keyframes ant01 {
      0% {
        left: 30px;
      }
      100% {
        left: 100px;
      }
    }
    @keyframes ant02 {
      0% {
        left: -30px;
      }
      100% {
        left: 50px;
      }
    }
  }
  .news-block {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    #event,
    #news {
      width: 48%;
      @include mq {
        width: 100%;
      }
    }
    #news {
      @include mq {
        margin-top: 50px;
      }
    }
    .news-list,
    .event-list {
      margin-bottom: 30px;
    }
    .news-list {
      li {
        .txt {
          text-overflow: ellipsis;
          display: -webkit-box;
          line-clamp: 1;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
      }
    }
    .event-list {
      li {
        a {
          .box-ttl {
            font-size: 1.6rem;
            @include mq(pc) {
              padding: 0;
              width: 110px;
            }
            .year-txt {
              font-size: 1.4rem;
            }
            .date-txt {
              font-size: 1.8rem;
              @include mq {
                font-size: 2rem;
              }
            }
          }
          .box-txt {
            font-size: 1.8rem;
            @include mq(pc) {
              width: calc(100% - 130px);
            }
            @include mq {
              font-size: 1.4rem;
            }
            .txt {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              line-clamp: 1;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
            }
            .status-box {
              .accepted-txt,
              .unaccept-txt,
              .members-txt,
              .attention-txt {
                padding: 2px 10px 0;
                @include mq {
                  padding: 3px 10px 2px;
                }
              }
            }
          }
        }
      }
    }
    
  }
  .news-list {
    li {
      a {
        padding: 25px 0;
        display: block;
        @include mq {
          padding: 20px 0;
        }
        &:hover {
          .txt {
            color: $color01;
          }
        }
      }
      > div {
        padding: 25px 0;
        @include mq {
          padding: 20px 0;
        }
      }
      border-bottom: 1px solid $gray01;
      display: block;
      span {
        display: block;
      }
      .ttl {
        padding-bottom: 10px;
        @include mq {
          font-size: 1.2rem;
        }
        .en {
          display: inline-block;
          font-weight: bold;
        }
      }
      .txt {
        font-size: 1.8rem;
        overflow: hidden;
        @include mq {
          font-size: 1.4rem;
        }
      }
      .category-txt {
        background: $color-bg02;
        display: inline-block;
        font-size: 1.4rem;
        margin-left: 10px;
        padding: 2px 10px 2px 10px;
        @include mq {
          font-size: 1.2rem;
        }
      }      
      &:nth-child(1) {
        a {
          padding-top: 0;
        }
        > div {
          padding-top: 0;
        }
      }
    }
  }
  .event-list {
    li {
      @include mq {
        + li {
          a {
            padding: 30px 0 0;
          }
        }
      }
      a {
        border-bottom: 1px solid $gray01;
        padding: 25px 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        @include mq {
          border-bottom: none;
          padding: 0;
        }
        &:hover {
          .box-txt {
            color: $color01;
          }
        }
      }
      .box-ttl {
        background: $color-bg03;
        font-weight: bold;
        font-size: 2rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 20px;
        text-align: center;
        @include mq {
          padding: 7px;
          font-size: 1.6rem;
          width: 100%;
        }
        .year-txt {
          font-size: 1.8rem;
          @include mq {
            font-size: 1.4rem;
          }
        }
        .date-txt {
          font-size: 2.6rem;
          @include mq {
            font-size: 2rem;
          }
        }
      }
      .box-txt {
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 2.4rem;
        width: calc(100% - 170px);
        @include mq {
          font-size: 1.8rem;
          margin-top: 10px;
          width: 100%;
        }
        .status-box {
          display: flex;
          width: 100%;
          align-items: center;
          margin: 0 -5px 10px;
          .accepted-txt,
          .unaccept-txt {
            background: $main-color;
            border: 1px solid $main-color;
            color: $white;
            font-size: 1.4rem;
            font-weight: bold;
            padding: 5px 10px 3px;
            margin: 0 5px;
            text-align: center;
            @include mq {
              font-size: 1.1rem;
              padding: 3px 10px 2px;
            }
          }
          .unaccept-txt {
            background: $white;
            color: $main-color;
          }
          .attention-txt {
            background: $white;
            border: 1px solid $text-color;
            color: $text-color;
            font-size: 1.4rem;
            font-weight: bold;
            padding: 5px 10px 3px;
            margin: 0 5px;
            text-align: center;
            @include mq {
              font-size: 1.1rem;
              padding: 3px 10px 2px;
            }
          }
          .members-txt {
            background: $white;
            border: 1px solid $color01;
            color: $color01;
            font-size: 1.4rem;
            font-weight: bold;
            padding: 5px 10px 3px;
            margin: 0 5px;
            text-align: center;
            @include mq {
              font-size: 1.1rem;
              padding: 3px 10px 2px;
            }
          }
        }
      }
      &.disable {
        a {
          opacity: 0.5;
        }
      }
      &:nth-child(1) {
        a {
          padding-top: 0;
        }
      }
    }
  }
  .list01 {
    margin-top: -20px;
    @include mq(pc) {
      display: flex;
      flex-wrap: wrap;
      margin: -20px -1% 0;
      li {
        margin: 0 1%;
        width: 23%;
      }
    }
    li {
      border-bottom: 1px solid $gray01;
      a {
        display: block;
        font-size: 1.6rem;
        height: 100%;
        padding: 20px 25px 20px 0;
        position: relative;
        @include mq {
          font-size: 1.4rem;
          padding: 20px 0;
        }
        .arrow {
          background: transparent;
          position: absolute;
          height: 20px;
          width: 20px;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          overflow: hidden;
          &::before,
          &::after {
            content: "";
            background: url(/img/common/arrow_02.svg) no-repeat left top;
            background-size: 100% 100%;
            height: 12px;
            width: 16px;
            position: absolute;
            margin-top: -5px;
            left: 0;
            top: 50%;
            transform: rotate(90deg) translateY(-50%);
          }
          &::after {
            left: -20px;
            transform: translate(-100%,0) rotate(90deg) translateY(-50%);
            @include mq {
              display: none;
            }
          }
        }
        &:hover {
          color: $color01;
          .arrow {
            &::before {
              @include mq(pc) {
                animation: nav-arrow01 0.5s alternate;
              }
            }
            &::after {
              @include mq(pc) {
                animation: nav-arrow02 0.5s alternate;
              }
            }
          }
        }
      }
    }
  }
  .pager-link {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    a {
      border: 1px solid $gray01;
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      color: $color05;
      font-size: 1.8rem;
      font-family: $font-roboto;
      font-weight: bold;
      height: 60px;
      width: 60px;
      margin: 0 5px;
      text-align: center;
      position: relative;
      @include mq {
        font-size: 1.4rem;
        margin: 0 3px;
        height: 40px;
        width: 40px;
      }
      &.active,
      &:hover {
        background: $color01;
        border-color: $color01;
        color: $white;
      }
      &.prev,
      &.next {
        margin: 0 0 0 30px;
        @include mq {
          margin: 0 0 0 15px;
        }
        .arrow {
          background: transparent;
          border-radius: 50%;
          -webkit-border-radius: 50%;
          display: block;
          height: 60px;
          width: 60px;
          position: absolute;
          top: 50%;
          left: 0px;
          transform: translateY(-50%);
          overflow: hidden;
          @include mq {
            height: 40px;
            width: 40px;
          }
          &::before,
          &::after {
            content: "";
            background-color:$color01;
            -webkit-mask-image: url(../../img/common/arrow_01.svg);
            mask-image: url(../../img/common/arrow_01.svg);
            -webkit-mask-repeat: no-repeat;
            mask-repeat: no-repeat;
            -webkit-mask-size: 100% 100%;
            mask-size: 100% 100%;
            height: 12px;
            width: 16px;
            position: absolute;
            left: 20px;
            top: 50%;
            transform: translateY(-50%);
            @include mq {
              left: 12px;
            }
          }
          &::after {
            left: -20px;
            transform: translate(-200%, -50%);
          }
        }
      }
      &.prev {
        margin: 0 30px 0 0;
        transform: rotate(180deg);
        @include mq {
          margin: 0 15px 0 0;
        }
      }
      &:hover {
        &::before {
          background: $white;
          border: 1px solid $gray01;
        }
        .arrow {
          &::before,
          &::after {
            background-color: $white;
          }
          &::before {
            animation: circle-arr1 0.5s alternate;
          }
          &::after {
            animation: circle-arr2 0.5s alternate;
          }
        }
      }
    }
    span {
      color: $color05;
    }
  }
  .breadcrumb {
    padding-bottom: 20px;
    &.blue-bg {
      background: $color-bg02;
    }
    @include mq {
      display: none;
    }
    .breadcrumb-box {
      font-size: 1.4rem;
      text-overflow: ellipsis;
      display: -webkit-box;
      line-clamp: 1;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      word-break: break-word;
      position: relative;
      overflow: hidden;
      a {
        position: relative;
        &::after {
          content: "/";
          font-size: 1.4rem;
          color: $color05;
          display: inline-block;
          padding-left: 5px;
        }
        &:hover {
          color: $color01;
        }
        &.none-b {
          &::after {
            display: none;
          }
        }
      }
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      position: relative;
      overflow: hidden;
      li {
        
        + li {
          padding-left: 5px;
          &::before {
            content: "/";
            font-size: 1.4rem;
            color: $color05;
            display: inline-block;
            padding-right: 5px;
          }
        }
      }
    }
  }
  .tbl-style01 {
    width: 100%;
    a {
      color: $color01;
      font-weight: bold;
      &:hover {
        opacity: 0.7;
        text-decoration: underline;
      }
    }    
    th {
      border-bottom: 1px solid $gray01;
      font-size: 2.4rem;
      font-weight: bold;
      padding: 30px 0;
      width: 180px;
      text-align: left;
      @include mq {
        border-bottom: none;
        font-size: 1.8rem;
        display: list-item;
        list-style: none;
        padding: 20px 0 0;
        width: 100%;
      }
    }
    td {
      border-bottom: 1px solid $gray01;
      font-size: 2rem;
      padding: 30px 0;
      @include mq {
        font-size: 1.6rem;
        display: list-item;
        list-style: none;
        padding: 5px 0 20px;
      }
    }
    tr:nth-child(1) {
      th {
        padding-top: 0;
      }
      @include mq(pc) {
        td {
          padding-top: 0;
        }
      }
    }
  }
  .tbl-style02 {
    width: 100%;
    th {
      background: $white;
      border: 1px solid $gray01;
      font-weight: 500;
      padding: 10px;
      text-align: center;
      &.col1 {
        width: 14%;
      }
      &.col2 {
        width: 18%;
      }
      @include mq {
        display: none;
      }
    }
    td {
      border: 1px solid $gray01;
      padding: 10px;
      @include mq {
        border: none;
        display: list-item;
        list-style: none;
        padding: 6px 0 6px 60px;
        position: relative;
        &:last-child {
          border-bottom: 1px solid $gray01;
          padding-bottom: 15px;
          margin-bottom: 15px;
        }
        .ttl_sp {
          background: $white;
          display: inline-block;
          padding: 3px 0;
          position: absolute;
          left: 0;
          top: 2px;
          width: 48px;
          text-align: center;
        }
      }
    }
    tr:last-child {
      td:last-child {
        margin-bottom: 0;
      }
    }
  }
  .form-block {
    .ttl-form01 {
      background: $color-bg03;
      font-size: 2.4rem;
      padding: 10px 15px;
      margin-bottom: 40px;
      @include mq {
        font-size: 1.8rem;
        margin-bottom: 30px;
      }
      &:nth-child(n+2) {
        margin-top: 100px;
        @include mq {
          margin-top: 80px;
        }
      }
    }
    .ttl-form02 {
      font-size: 2.4rem;
      font-weight: normal;
      padding-left: 15px;
      margin-bottom: 40px;
      position: relative;
      &::before {
        content: "";
        background: #0081CC;
        height: 36px;
        width: 2px;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
    .ttl-form03 {
      display: block;
      font-size: 1.8rem;
      margin-bottom: 10px;
      @include mq {
        font-size: 1.6rem;
      }
    }
    .ttl-form04 {
      font-size: 2rem;
      @include mq {
        font-size: 1.6rem;
      }
    }
    .form-txt {
      margin-bottom: 50px;
    }
    .person-box {
      margin-top: 40px;
    }
    .voting-box {
      .txt {
        @include mq {
          font-size: 1.4rem!important;
        }
      }
      + .voting-box {
        margin-top: 40px;
      }
    }
    .form-list {
      dt {
        font-size: 2.4rem;
        font-weight: bold;
        margin-bottom: 10px;
        .request-txt {
          background: $main-color;
          color: $white;
          font-size: 1.4rem;
          display: inline-block;
          padding: 6px 0 4px;
          margin-right: 10px;
          width: 48px;
          vertical-align: top;
          text-align: center;
          @include mq {
            font-size: 1.1rem;
            padding: 4px 0 3px;
            width: 42px;
          }
        }
        .any-txt {
          background: $white;
          border: 1px solid $main-color;
          font-size: 1.4rem;
          display: inline-block;
          padding: 6px 0 4px;
          margin-right: 10px;
          width: 48px;
          vertical-align: top;
          text-align: center;
          @include mq {
            font-size: 1.1rem;
            padding: 4px 0 3px;
            width: 42px;
          }
        }
        @include mq { 
          font-size: 1.8rem;
        }
        &:nth-child(n+2) {
          margin-top: 40px;
          @include mq {
            margin-top: 30px;
          }
        }
      }
      dd {
        input[type="text"],
        input[type="tel"],
        input[type="email"],
        input[type="password"],
        input[type="date"],
        textarea {
          background: $white;
          border: 1px solid $gray01;
          border-radius: 5px;
          -webkit-border-radius: 5px;
          color: $main-color;
          font-size: 2rem;
          padding: 20px;
          vertical-align: middle;
          height: 60px;
          width: 100%;
          @include mq {
            font-size: 1.6rem;
            padding: 10px 15px;
            height: 54px;
          }
          &.w-01 {
            width: 180px;
          }
        }
        ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
          color: $color05;
        }
        ::-moz-placeholder { /* Firefox 19+ */
          color: $color05;
        }
        :-ms-input-placeholder { /* IE 10+ */
          color: $color05;
        }
        :-moz-placeholder { /* Firefox 18- */
          color: $color05;
        }
        textarea {
          height: 150px;
          resize: vertical;
          white-space: pre-line;
          @include mq {
            height: 150px;
          }
        }
        .custome-select {
          background: $white;
          border: 1px solid $gray01;
          border-radius: 5px;
          -webkit-border-radius: 5px;
          height: 60px;
          display: inline-block;
          position: relative;
          z-index: 3;
          width: 100%;
          &::after {
            content: "";
            border: solid $main-color;
            border-width: 0 2px 2px 0;
            display: inline-block;
            padding: 3px;
            position: absolute;
            top: 22px;
            right: 20px;
            z-index: 5;
            transform: rotate(45deg);
          }
          @include mq {
            height: 54px;
          }
          select {
            background: transparent;
            padding: 0 30px 0 20px;
            font-size: 2rem;
            color: $main-color;
            height: 100%;
            width: 100%;
            z-index: 2;
            position: relative;
            @include mq {
              font-size: 1.6rem;
            }
          }
          &.w-01 {
            width: 124px;
          }
          &.w-02 {
            width: 250px;
          }
        }
        .input-calendar {
          position: relative;
          width: 180px;
          @include mq {
            width: 100%;
          }
          input {
            padding-left: 50px;
            width: 100%;
            @include mq {
              padding-left: 50px;
            }
          }
          label {
            cursor: pointer;
            display: block;
            position: relative;
            &::after {
              content: '';
              background-color: $text-color;
              -webkit-mask-image: url(../../img/common/calendar_icon.svg);
              mask-image: url(../../img/common/calendar_icon.svg);
              -webkit-mask-repeat: no-repeat;
              mask-repeat: no-repeat;
              -webkit-mask-size: 100% 100%;
              mask-size: 100% 100%;
              height: 24px;
              width: 24px;
              position: absolute;
              top: 50%;
              left: 20px;
              transform: translateY(-50%);
            }
          }
        }
        .lab-txt {
          font-size: 2rem;
          margin-left: 10px;
          @include mq {
            font-size: 1.6rem;
          }
        }
        .txt {
          padding-bottom: 10px;
          @include mq {
            font-size: 1.6rem;
          }
        }
        .row {
          + .row {
            margin-top: 10px;
          }
          span.en {
            margin-right: 10px;
          }
          input.w-01 {
            width: 130px;
          }
        }
        .textarea-box {
          position: relative;
          .note-txt {
            display: block;
            font-size: 1.2rem;
            font-family: $font-roboto;
            font-weight: 500;
            padding-top: 10px;
            text-align: right;
            @include mq(pc) {
              position: absolute;
              right: 0;
              bottom: -25px;
            }
          }
        }
        .flex-input {          
          @include mq(pc) {
            display: flex;
            flex-wrap: wrap;
            gap: 20px;
            > p {
              width: 220px;
            }
          }
          @include mq {
            p + p {
              margin-top: 10px;
            }
          }
        }  
      }
      &.faq-form {
        dt {
          padding-left: 100px;
          text-indent: -100px;
          @include mq {
            padding-left: 87px;
            text-indent: -87px;
          }
          span {
            text-indent: 0;
          }
        }
      }
    }
    .error-txt {
      color: $color04;
      display: block;
      padding-top: 10px;
    }
    .thanks-txt {
      font-size: 2rem;
      padding-bottom: 50px;
      @include mq {
        font-size: 1.6rem;
      }
      &.txt-c {
        @include mq {
          text-align: left;
        }
      }
    }
    .box-img {
      height: 200px;
      width: 323px;
      position: relative;
      overflow: hidden;
      @include mq {
        height: 56vw;
        width: 100%;
      }
      img {
        object-fit: cover;
        height: 100%;
        width: 100%;
      }
      &.photo {
        display: none;
      }
    }
    .input-list {
      li {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        + li {
          margin-top: 10px;
        }
        .ttl {
          font-size: 2rem;
          width: 112px;
          @include mq {
            font-size: 1.6rem;
            width: 100px;
          }
        }
        .w-01 {
          width: 120px!important;
          text-align: right;
        }
      }
    }
    .check-list {
      &.d-fl {
        display: flex;
        flex-wrap: wrap;
        margin: -10px -5px 10px;
        li {
          margin: 10px 5px 0;
        }
      }
      li {
        + li {
          margin-top: 10px;
        }
      }
      .check-style {
        display: inline-block;
        position: relative;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
      
      /* Hide the browser's default radio button */
      .check-style input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
      }
      
      /* Create a custom radio button */
      .checkmark {
        background: $white;
        border: 1px solid $gray01;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        font-size: 2rem;
        display: block;
        padding: 14px 15px 14px 45px;
        min-width: 145px;
        position: relative;
        @include transition;
        @include mq {
          font-size: 1.6rem;
          min-width: 120px;
        }
        &:before {
          border: 2px solid $main-color;
          border-radius: 50%;
          -webkit-border-radius: 50%;
          content: "";
          position: absolute;
          left: 20px;
          top: 50%;
          transform: translateY(-50%);
          width: 13px;
          height: 13px;
          @include mq {
            top: 49%;              
          }
        }
        &::after {
          content: "";
          background: $color01;
          border-radius: 50%;
          -webkit-border-radius: 50%;
          height: 9px;
          width: 9px;
          position: absolute;
          left: 24px;
          top: 50%;
          transform: translateY(-50%);
          display: none;
          @include mq {
            top: 49%;              
          }
        }
      }
      .check-style input:checked ~ .checkmark {
        background-color: $color-bg02;
        border-color: $color01;
      } 
      @include mq(pc) {
        .check-style:hover input ~ .checkmark {
          background-color: $color-bg02;
          border-color: $color01;
        } 
      }
      /* Show the indicator (dot/circle) when checked */
      .check-style input:checked ~ .checkmark:before {
        border: 2px solid $color01;
      }
      .check-style input:checked ~ .checkmark:after {
        display: block;
      }
      &._checkbox {
        .checkmark {
          &::before {
            border-radius: 3px;
            -webkit-border-radius: 3px;
            height: 11px;
            width: 11px;
          }
          &:after {
            background: none;
            border-radius: 0;
            -webkit-border-radius: 0;
            left: 22px;
            margin-top: -3px;
            top: 50%;
            width: 3px;
            height: 7px;
            border: solid $color01;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg) translateY(-50%);
            @include mq {
              width: 3px;
              height: 6px;
            }
          }
        }
      }
      &._w-01 {
        margin-bottom: 10px;
        .checkmark {
          width: 622px;
          @include mq {
            width: 300px;
          }
        }
      }
      &.voting-check {
        margin-bottom: 10px;
        li {
          .checkmark {
            width: auto;
          }
        }
      }
    }
    .step-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: 0 auto 50px;
      max-width: 508px;
      li {
        color: $color05;
        font-size: 2.4rem;
        font-weight: bold;
        position: relative;
        text-align: center;
        width: 33.333%;
        @include transition;
        @include mq {
          font-size: 1.4rem;
        }
        + li {
          &::after {
            content: "";
            background: $gray03;
            height: 3px;
            width: 100%;
            position: absolute;
            left: -50%;
            top: 28px;
            z-index: -1;
            @include mq {
              top: 18px;
            }
          }
        }
        .icon {
          background: $gray03;
          border-radius: 50%;
          -webkit-border-radius: 50%;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          height: 57px;
          width: 57px;
          margin: 0 auto 20px;
          @include transition;
          @include mq {
            height: 37px;
            width: 37px;
            margin-bottom: 10px;
            img {
              width: 50%;
            }
          }
        }
        .txt {
          display: block;
        }
        &.active {
          color: $main-color;
          .icon {
            background: $color01;
          }
        }
      }
      &._col2 {
        li {
          width: 50%;
        }
      }
    }
    .policy-box {
      background: $color-bg03;
      padding: 25px;
      margin-bottom: 10px;
      @include mq {
        padding: 20px;
      }
      > div {
        background: $white;
        font-size: 1.8rem;
        padding: 20px;
        @include mq {
          font-size: 1.6rem;
          padding: 15px;
        }
      }
    }
    .btn-file {
      margin: 20px 0 10px;
      .file {
        position: relative;
        display: inline-block;
        cursor: pointer;
        background: $color01;
        border: 1px solid $color01;
        border-radius: 50px;
        -webkit-border-radius: 50px;
        display: inline-flex;
        color: $white;
        cursor: pointer;
        font-size: 1.6rem;
        font-weight: bold;
        position: relative;
        width: 182px;
        @include transition;
        @include mq {
          width: 60%;
        }
        input {
          height: 0;
          width: 0;
          margin: 0;
          opacity: 0;
        }
        .file-custom {
          display: block;
          padding: 15px 43px 15px 20px;
          width: 100%;
          -webkit-user-select: none;
             -moz-user-select: none;
              -ms-user-select: none;
                  user-select: none;
          position: relative;
          &::after {
            content: "ファイルを選択";
          }
          @include mq {
            padding: 15px 42px 15px 20px;
          }
        }
        .arrow {
          background: transparent;
          border-radius: 30px;
          display: inline-block;
          height: 40px;
          width: 40px;
          right: 0;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          overflow: hidden;
          &::before,
          &::after {
            content: "";
            background-color: $white;
            -webkit-mask-image: url(../../img/common/upload_icon.svg);
            mask-image: url(../../img/common/upload_icon.svg);
            -webkit-mask-repeat: no-repeat;
            mask-repeat: no-repeat;
            -webkit-mask-size: 100% 100%;
            mask-size: 100% 100%;
            height: 20px;
            width: 20px;
            position: absolute;
            left: 5px;
            top: 50%;
            transform: translateY(-50%);
          }
          &::after {
            left: -15px;
            margin-left: 20px;
            transform: translate(-300%, 0) translateY(-50%);
          }
        }
        &:hover {
          background: $white;
          border-color: $gray01;
          color: $color01;
          .arrow {
            &::before,
            &::after {
              background-color: $color01;
            }
            &::before {
              animation: btn-arrow01 0.5s alternate;
            }
            &::after {
              animation: btn-arrow02 0.5s alternate;
            }
          }
        }
      }
    }
    .box-btn {
      @include mq(pc) {
        ._red {
          margin-right: 10px;
          order: 1;
        }
      }
    }
  }
  &.conf {
    .form-list {
      dd {
        font-size: 2rem;
        @include mq {
          font-size: 1.6rem;
        }
      }
    }
  }
  .ma-b10 {
    margin-bottom: 10px;
  }
  .anchor-link {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px 50px;
    li {
      padding: 0 10px;
      width: 25%;
      @include mq {
        width: 50%;
      }
      a {
        border-bottom: 1px solid $gray01;
        display: block;
        padding: 15px 20px 15px 0;
        position: relative;
        @include mq {
          padding: 15px 20px 15px 0;
        }
        .arrow {
          background: transparent;
          position: absolute;
          height: 30px;
          width: 20px;
          right: 0;
          top: 6px;
          overflow: hidden;
          &::before,
          &::after {
            content: "";
            background: url(/img/common/arrow_02.svg) no-repeat left top;
            background-size: 100% 100%;
            height: 17px;
            width: 12px;
            position: absolute;
            left: 8px;
            top: 10px;
            transform: rotate(180deg);
          }
          &::after {
            top: -20px;
            transform: translateY(-100%) rotate(180deg);
          }
        }
        &:hover {
          color: $color01;
          .arrow {
            &::before {
              animation: anchor-arrow01 0.5s alternate;
            }
            &::after {
              animation: anchor-arrow02 0.5s alternate;
            }
          }
        }
      }
    }
    &.col-5 {
      li {
        width: 20%;
        @include mq {
          width: 33.333%;
        }
      }
    }
    @keyframes anchor-arrow01 {
      0% {
        top: 10px;
      }
      100% {
        top: 50px;
      }
    }
    @keyframes anchor-arrow02 {
      0% {
        top: -20px;
      }
      100% {
        top: 26px;
      }
    }
  }
  @keyframes animate-arrow01 {
    0% {
      left: 12px;
    }
    100% {
      left: 50px;
    }
  }
  @keyframes animate-arrow02 {
    0% {
      left: -10px;
    }
    100% {
      left: 44px;
    }
  }
}
.btn {
  margin-top: 30px;
  text-align: center;
  label,
  a {
    background: $color01;
    border: 1px solid $color01;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    display: inline-flex;
    color: $white;
    cursor: pointer;
    font-size: 1.6rem;
    font-weight: bold;
    padding: 15px 43px 15px 20px;
    position: relative;
    width: 300px;
    @include transition;
    @include mq {
      padding: 15px 42px 15px 20px;
      width: 90%;
    }
    .arrow {
      background: transparent;
      border-radius: 30px;
      display: inline-block;
      height: 40px;
      width: 40px;
      right: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      overflow: hidden;
      &::before,
      &::after {
        content: "";
        background-color: $white;
        -webkit-mask-image: url(../../img/common/arrow_01.svg);
        mask-image: url(../../img/common/arrow_01.svg);
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
        -webkit-mask-size: 100% 100%;
        mask-size: 100% 100%;
        height: 10px;
        width: 13px;
        position: absolute;
        left: 5px;
        top: 50%;
        transform: translateY(-50%);
      }
      &::after {
        left: -15px;
        margin-left: -1px;
        transform: translate(-300%, 0) translateY(-50%);
      }
    }
    &:hover {
      background: $white;
      border-color: $gray01;
      color: $color01;
      .arrow {
        &::before,
        &::after {
          background-color: $color01;
        }
        &::before {
          animation: btn-arrow01 0.5s alternate;
        }
        &::after {
          animation: btn-arrow02 0.5s alternate;
        }
      }
    }
  }
  &._black {
    label,
    a {
      background: $main-color;
      border: 1px solid $main-color;
      color: $white;
      padding: 15px 20px 15px 45px;
      width: 102px;
      .arrow {
        right: auto;
        left: 0;
        &::before,
        &::after {
          left: 20px;
          transform: translateY(-50%) rotate(180deg);
        }
        &::after {
          left: 45px;
          margin-left: -4px;
          transform: translateY(-50%) rotate(180deg);
        }
      }      
      &:hover {
        background: $white;
        border-color: $gray01;
        color: $main-color;
        .arrow {
          &::before,
          &::after {
            background-color: $main-color;
          }
          &::before {
            animation: btn-arrow03 0.5s alternate;
          }
          &::after {
            animation: btn-arrow04 0.5s alternate;
          }
        }
      }
    }
  }
  label {
    margin: 0 10px;
    @include mq {
      margin: 0;
    }
    input {
      position: absolute;
      height: 0;
      width: 0;
      opacity: 0;
    }
  }
  &._red {
    label,
    a {
      background: $color04;
      border-color: $color04;
      &:hover {
        background: $white;
        border-color: $gray01;
        color: $color04;
        .arrow {
          &::before,
          &::after {
            background-color: $color04;
          }
        }
      }
    }
  }
  &._gray {
    label,
    a {
      background: $color-bg02;
      border-color: $color-bg02;
      color: $main-color;
      .arrow {
        &::before,
        &::after {
          background-color: $main-color;
        }
      }
      &:hover {
        background: $color-bg02;
        border-color: $color-bg02;
        opacity: 0.7;  
      }
    }
  }
  &.active {
    a {
      background: $white;
      border-color: $gray01;
      color: $color01;
      pointer-events: none;
      cursor: auto;
      .arrow {
        &::before,
        &::after {
          background-color: $color01;
        }
      }
    }
  }
  &.pdf-icon {
    a,label {
      .arrow {
        &::before,
        &::after {
          -webkit-mask-image: url(../../img/common/pdf_icon.svg);
          mask-image: url(../../img/common/pdf_icon.svg);
          -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
          -webkit-mask-size: 100% 100%;
          mask-size: 100% 100%;
          height: 20px;
          width: 20px;
        }
        &::before {
          margin-left: -1px;
        }
        &::after {
          margin-left: 18px;
        }
      }
    }
  }
  &.download-icon {
    a,label {
      .arrow {
        &::before,
        &::after {
          -webkit-mask-image: url(../../img/common/download_icon.svg);
          mask-image: url(../../img/common/download_icon.svg);
          -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
          -webkit-mask-size: 100% 100%;
          mask-size: 100% 100%;
          height: 17px;
          width: 17px;
        }
        &::before {
          margin-left: -1px;
        }
        &::after {
          margin-left: 10px;
        }
      }
    }
  }
  &.blank-icon {
    a,label {
      .arrow {
        &::before,
        &::after {
          -webkit-mask-image: url(../../img/common/blank_icon.svg);
          mask-image: url(../../img/common/blank_icon.svg);
          -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
          -webkit-mask-size: 100% 100%;
          mask-size: 100% 100%;
          height: 15px;
          width: 15px;
        }
        &::before {
          margin-left: 0px;
        }
        &::after {
          margin-left: 5px;
        }
      }
    }
  }
  &.delete-icon {
    a,label {
      .arrow {
        &::before,
        &::after {
          -webkit-mask-image: url(../../img/common/delete_icon_01.svg);
          mask-image: url(../../img/common/delete_icon_01.svg);
          -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
          -webkit-mask-size: 100% 100%;
          mask-size: 100% 100%;
          height: 20px;
          width: 20px;
        }
        &::before {
          margin-left: 0px;
        }
        &::after {
          margin-left: 20px;
        }
      }
    }
  }
  &.cancel-icon {
    a,label {
      .arrow {
        &::before,
        &::after {
          -webkit-mask-image: url(../../img/common/cancel_icon.svg);
          mask-image: url(../../img/common/cancel_icon.svg);
          -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
          -webkit-mask-size: 100% 100%;
          mask-size: 100% 100%;
          height: 20px;
          width: 20px;
        }
        &::before {
          margin-left: 0px;
        }
        &::after {
          margin-left: 20px;
        }
      }
    }
  }
  &.add-icon {
    a,label {
      .arrow {
        &::before,
        &::after {
          -webkit-mask-image: url(../../img/common/add_icon.svg);
          mask-image: url(../../img/common/add_icon.svg);
          -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
          -webkit-mask-size: 100% 100%;
          mask-size: 100% 100%;
          height: 20px;
          width: 20px;
        }
        &::before {
          margin-left: 0px;
        }
        &::after {
          margin-left: 20px;
        }
      }
    }
  }
  @keyframes btn-arrow01 {
    0% {
      left: 5px;
    }
    100% {
      left: 50px;
    }
  }
  @keyframes btn-arrow02 {
    0% {
      left: -15px;
    }
    100% {
      left: 45px;
    }
  }
  @keyframes btn-arrow03 {
    0% {
      left: 10px;
    }
    100% {
      left: -50px;
    }
  }
  @keyframes btn-arrow04 {
    0% {
      left: 45px;
    }
    100% {
      left: 24px;
    }
  }
}
.link-txt {
  a {
    display: inline-block;
    padding: 7px 50px 8px 0;
    position: relative;
    font-size: 1.8rem;
    color: $main-color!important;
    font-weight: normal!important;
    &::before {
      content: "";
      background: $color01;
      border: 1px solid $color01;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      height: 40px;
      width: 40px;
      position: absolute;
      top: 50%;
      right: 0;
      @include transition;
      box-sizing: border-box;
      transform: translateY(-50%);
    }
    .arrow {
      background: transparent;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      display: block;
      height: 40px;
      width: 40px;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      overflow: hidden;
      &::before,
      &::after {
        content: "";
        background-color: $white;
        -webkit-mask-image: url(../../img/common/arrow_01.svg);
        mask-image: url(../../img/common/arrow_01.svg);
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
        -webkit-mask-size: 100% 100%;
        mask-size: 100% 100%;
        height: 12px;
        width: 16px;
        position: absolute;
        left: 12px;
        top: 50%;
        transform: translateY(-50%);
      }
      &::after {
        left: -10px;
        transform: translate(-200%, -50%);
      }
    }
    &:hover {
      opacity: 1!important;
      text-decoration: none!important;
      &::before {
        background: $white;
        border: 1px solid $gray01;
      }
      .arrow {
        &::before,
        &::after {
          background-color: $color01;
        }
        &::before {
          animation: animate-arrow01 0.5s alternate;
        }
        &::after {
          animation: animate-arrow02 0.5s alternate;
        }
      }
      
    }
  }
  &.blank-icon {
    a {
      .arrow {
        &::before,
        &::after {
          -webkit-mask-image: url(../../img/common/blank_icon.svg);
          mask-image: url(../../img/common/blank_icon.svg);
          -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
          -webkit-mask-size: 100% 100%;
          mask-size: 100% 100%;
          height: 15px;
          width: 15px;
        }
        &::before {
          margin-left: 0px;
        }
        &::after {
          margin-left: -2px;
        }
      }
    }
  }
  &.pdf-icon {
    a {
      .arrow {
        &::before,
        &::after {
          -webkit-mask-image: url(../../img/common/pdf_icon.svg)!important;
          mask-image: url(../../img/common/pdf_icon.svg)!important;
          -webkit-mask-repeat: no-repeat!important;
          mask-repeat: no-repeat!important;
          -webkit-mask-size: 100% 100%;
          mask-size: 100% 100%;
          height: 20px!important;
          width: 20px!important;
        }
        &::before {
          margin-left: -1px;
        }
        &::after {
          margin-left: 6px;
        }
      }
    }
  }
  &._back {
    a {
      .arrow {
        right: auto;
        left: 0;
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }
  &.question-icon {
    a {
      .arrow {
        &::before,
        &::after {
          -webkit-mask-image: url(../../img/common/question_icon.svg);
          mask-image: url(../../img/common/question_icon.svg);
          -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
          -webkit-mask-size: 100% 100%;
          mask-size: 100% 100%;
          height: 20px;
          width: 20px;
        }
        &::before {
          margin-left: -2px;
        }
        &::after {
          margin-left: 5px;
        }
      }
    }
  }
}